import { useParams } from "react-router";
import SequenceNav from "./SequenceNav";
import { useEffect, useState } from "react";
import { Tooltip } from 'react-tooltip';
import EmailSequenceService from "../Services/EmailSequenceService";
import moment from "moment";
import UtilsService from "../Services/UtilsService";
import SequenceEmailOffCanvas from "../Offcanvas/SequenceEmailOffcanvas";

function SequenceEmails() {

    const { id: sequence_id } = useParams();

    const [emailSequences, setEmailSequences] = useState([]);
    const [filteredEmailSequences, setFilteredEmailSequences] = useState([]);
    const [summary, setSummary] = useState({});
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedEmailSequence, setSelectedEmailSequence] = useState(null);
    const onClose = () => setShow(false);


    useEffect(() => {
        getEmailSequences();
        getEmailSequenceSummary();
    }, []);

    useEffect(() => {
        filterEmailSequences();
    }, [search])

    function filterEmailSequences() {

        if (search) {
            let filteredEmailSequences = emailSequences.filter(emailSequence => {
                return emailSequence.lead.email.toLowerCase().includes(search.toLowerCase()) ||
                    emailSequence.lead.name.toLowerCase().includes(search.toLowerCase());
            });

            setFilteredEmailSequences(filteredEmailSequences);
        } else {
            setFilteredEmailSequences([]);
        }

    }

    async function getEmailSequences() {
        let { data } = await EmailSequenceService.getEmailSequencesBySequenceId(sequence_id);
        setEmailSequences(data);
    }

    async function getEmailSequenceSummary() {
        let summary = await EmailSequenceService.getEmailSequenceSummaryBySequenceId(sequence_id);
        setSummary(summary);
    }

    function showSequenceEmailsDetails(emailSequence) {
        setSelectedEmailSequence(emailSequence);
        setShow(true);
    }



    function renderEmailSequences() {

        let dateFormat = 'MM/DD/YYYY hh:mm:ss A';

        let localEmailSequences = search ? filteredEmailSequences : emailSequences;

        return localEmailSequences.map((emailSequence, i) => {

            let stepIndex = emailSequence.current_step - 1;
            let step = emailSequence.steps[stepIndex];

            if (!step) {
                step = emailSequence.steps[emailSequence.steps.length - 1];
            }

            let buttonsHTML = emailSequence.steps.map((step, i) => {

                let className = 'btn btn-outline-primary btn-sm btn-circle mx-1';
                let tooltipContent = `Email not sent yet, estimated to be sent on ${moment(step.estimated_send_at).format(dateFormat)}`;
                if (step.email_log && step.status === 'sent' && step.email_log.sent_result === 'email sent') {
                    className = 'btn btn-outline-success btn-sm btn-circle mx-1';
                    tooltipContent = `Email successfully sent at ${moment(step.email_log.sent_at).format(dateFormat)}`;
                } else if (step.email_log && step.email_log.sent_result !== 'email sent') {
                    className = 'btn btn-outline-danger btn-sm btn-circle mx-1';
                    tooltipContent = `Email failed with error ${step.email_log.sent_result} at ${moment(step.email_log.sent_at).format(dateFormat)}`;
                } else if (step.status === 'replied') {
                    className = 'btn btn-outline-info btn-sm btn-circle mx-1';
                    tooltipContent = `Email replied at ${moment(step.replied_at).format(dateFormat)}`;
                } else if (step.status === 'bounced') {
                    className = 'btn btn-outline-info btn-sm btn-circle mx-1';
                    tooltipContent = `Email not sent, reason: bounced at ${moment(step.bounced_at).format(dateFormat)}`;
                } else if (step.status === 'error') {
                    className = 'btn btn-outline-danger btn-sm btn-circle mx-1';
                    tooltipContent = `Email not sent, reason: ${step.error} at ${moment(step.error_at).format(dateFormat)}`;
                } else {
                    className = 'btn btn-outline-warning btn-sm btn-circle mx-1';
                }

                return <button
                    data-tooltip-id="sequence-emails-tooltip"
                    data-tooltip-content={tooltipContent}
                    className={className}>S{i + 1}</button>
            });

            return (
                <div key={`email-sequence-${i}`} className='email-sequence-item'>
                    <div className="form-check">
                        <input className="form-check-input"
                            type="checkbox" value="" id={'sequence-email-' + i} />
                    </div>

                    <span onClick={() => showSequenceEmailsDetails(emailSequence)} className="fs-13" style={{ minWidth: '160px', maxWidth: '160px' }}>
                        To  <span className='text-primary bold' >
                            {emailSequence.lead.name}
                        </span>
                    </span>

                    <div className="fs-12" onClick={() => showSequenceEmailsDetails(emailSequence)}>
                        <div className="bold mb-1">
                            {UtilsService.replaceTextWithUserAndLeadVariables(step.sequence_step.subject, emailSequence.user, emailSequence.lead)}
                        </div>
                        <div>{UtilsService.replaceTextWithUserAndLeadVariables(step.sequence_step.textBody.substring(0, 300), emailSequence.user, emailSequence.lead)}...</div>
                    </div>

                    <div className="d-flex">
                        {buttonsHTML}
                    </div>

                </div>
            )
        })
    }

    return (
        <div>

            <SequenceEmailOffCanvas
                show={show}
                onClose={onClose}
                selectedEmailSequence={selectedEmailSequence}
            />

            <SequenceNav />
            <div className="container">
                <div className="sequence-detail-container">

                    <div className='email-sequence-contacts-stats'>
                        <input type="text"
                            value={search}
                            className="input-search"
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="search by email" />
                    </div>

                    <div className='email-sequence-contacts-stats'>
                        <div className='sequence-contacts-stats-item active'>
                            <div className='bold'>{summary.total}</div>
                            <div> Total</div>
                        </div>

                        <div className='sequence-contacts-stats-item'>
                            <div className='bold'>{summary.pending}</div>
                            <div>Pending</div>
                        </div>

                        <div className='sequence-contacts-stats-item'>
                            <div className='bold'>{summary.sent}</div>
                            <div>Sent 1st email</div>
                        </div>
                        <div className='sequence-contacts-stats-item'>
                            <div className='bold'>{summary.sentStep2}</div>
                            <div>Sent 2nd email</div>
                        </div>



                    </div>

                    {renderEmailSequences()}


                </div>
            </div>
            <Tooltip id="sequence-emails-tooltip" />
        </div>
    )

}

export default SequenceEmails;