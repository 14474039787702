import { Link } from 'react-router-dom';
import Logo from '../assets/imgs/ulysses-leads-wide.png';
import AuthService from '../Services/AuthService';
import { useEffect, useState } from 'react';


function Navbar() {

  const [user, setUser] = useState(null);

  const getCurrentUser = async () => {
    let user = await AuthService.getCurrentUser();
    setUser(user);
  }

  const logOut = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      AuthService.logout();
      window.location.href = '/signin';
    }

  }

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <nav className='ulysses-navbar'>

      <Link to={'/'}>
        <img src={Logo} width={250} alt="" />

      </Link>

      {(user && user.email) ? (
        <button onClick={logOut} className="btn btn-transparent">Log out</button>
      ) : (
        <Link to={'/login'} className="btn btn-transparent">
          Log in
        </Link>
      )}


    </nav>
  );
}

export default Navbar;