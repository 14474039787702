import React, { createContext, useContext, useState } from 'react';

// Paso 1: Crear el contexto
const InboxComposeContext = createContext();

// Paso 2: Proveedor del contexto
export const InboxComposeProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [from, setFrom] = useState('');
    const [to, setTo] = useState('');
    const [subject, setSubject] = useState('');

    const openCompose = ({initialFrom, initialTo, initialSubject}) => {
        setIsVisible(true);
        setTo(initialTo);
        setSubject(initialSubject);
        setFrom(initialFrom)
    };

    const closeCompose = () => {
        setIsVisible(false);
    };

    return (
        <InboxComposeContext.Provider value={{ isVisible, to, setTo, subject, setSubject, from, setFrom, openCompose, closeCompose }}>
            {children}
        </InboxComposeContext.Provider>
    );
};

// Hook personalizado para acceder al contexto del modal
export const useInboxCompose = () => {
    return useContext(InboxComposeContext);
};