import { NavLink } from "react-router-dom";
import UtilsService from '../Services/UtilsService';

function InboxThreadRow({ email }) {
    return (
        <li class="list-group-item list-group-item-action inbox-email-row">
            <div
                data-tooltip-id="inbox-tooltip"
                data-tooltip-content="Select"
                className="inbox-email-checkbox">
                <input className="form-check-input"
                    type="checkbox" id={`${email.date}-checkbox`} value={true} />
            </div>

            <div
                data-tooltip-id="inbox-tooltip"
                data-tooltip-content="Mark as starred"
                className="inbox-email-star">
                <i className="fa-regular fa-star"></i>
            </div>


            <NavLink
                to={`/admin/inbox/${email.thread_id}`}
                className="inbox-email-from">
                {email.recipients}
            </NavLink>

            <span
                data-tooltip-id="inbox-tooltip"
                data-tooltip-content={email.account}
                class="badge badge-inbox me-2">{email.initials}</span>

            <NavLink
                to={`/admin/inbox/${email.thread_id}`}
                className="inbox-email-subject">

                {email.subject} - {email.snippet}
            </NavLink>

            <div className="inbox-email-date">
                {UtilsService.formatDateForInbox(email.last_message_date)}
            </div>

            <div className="inbox-hide-actions">
                <button
                    data-tooltip-id="inbox-tooltip"
                    data-tooltip-content="Archive"
                >
                    <i className="fa-solid fa-box-archive"></i>
                </button>
                <button
                    data-tooltip-id="inbox-tooltip"
                    data-tooltip-content="Delete"
                >
                    <i className="fa-regular fa-trash-can"></i>

                </button>
                <button data-tooltip-id="inbox-tooltip"
                    data-tooltip-content="Mark as unread">
                    <i className="fa-regular fa-envelope"></i>

                </button>

            </div>

        </li>

    )

}

export default InboxThreadRow;