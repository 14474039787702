import { CREATE_EMAIL_SEQUENCE_FROM_LEADS_IDS, CREATE_EMAIL_SEQUENCE_FROM_LEADS_IDS_PLACEHOLDER, GET_EMAIL_SEQUENCES_BY_SEQUENCE_ID, GET_EMAIL_SEQUENCES_LOGS_BY_SEQUENCE_ID, GET_EMAIL_SEQUENCE_SUMMARY_BY_SEQUENCE_ID, REMOVE_EMAIL_SEQUENCES_LEADS_IDS } from "../constants";
import Requests from "../requests";


const EmailSequenceService = {
    createEmailSequenceFromLeadsIdsUsingPlaceholder({ sequence_id, leads_id }) {
        return Requests.postWithAuth(CREATE_EMAIL_SEQUENCE_FROM_LEADS_IDS_PLACEHOLDER, { sequence_id, leads_id });
    },
    createEmailSequenceFromLeadsIds({ sequence_id, leads_id }) {
        return Requests.postWithAuth(CREATE_EMAIL_SEQUENCE_FROM_LEADS_IDS, { sequence_id, leads_id });
    },
    getEmailSequencesBySequenceId(sequence_id) {
        return Requests.getWithAuth(GET_EMAIL_SEQUENCES_BY_SEQUENCE_ID.replace(':id', sequence_id));
    },
    getEmailSequencesLogsBySequenceId(sequence_id) {
        return Requests.getWithAuth(GET_EMAIL_SEQUENCES_LOGS_BY_SEQUENCE_ID.replace(':id', sequence_id));
    },
    getEmailSequenceSummaryBySequenceId(sequence_id) {
        return Requests.getWithAuth(GET_EMAIL_SEQUENCE_SUMMARY_BY_SEQUENCE_ID.replace(':id', sequence_id));
    },
    removeLeadsFromSequence({ sequence_id, leads_id }) {
        return Requests.postWithAuth(REMOVE_EMAIL_SEQUENCES_LEADS_IDS, { sequence_id, leads_id });
    }
}

export default EmailSequenceService