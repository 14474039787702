import { useEffect, useState } from 'react';
import LeadsService from '../Services/LeadsService';
import PubSub from 'pubsub-js';
import csv2json from 'csvjson-csv2json';
import SequenceService from '../Services/SequenceService';

function AdminLeads() {

    const [leadsText, setLeadsText] = useState('');
    const [leadsCount, setLeadsCount] = useState(0);

    useEffect(() => {
        getLeadsCount();
    }, []);

    async function getLeadsCount() {
        let { count } = await LeadsService.getLeadsAvailableCount();
        setLeadsCount(count);
    }

    function mapLeads() {
        const json = csv2json(leadsText, { parseNumbers: true });
        return json;
    }

    async function uploadLeads() {
        let leadsMapped = mapLeads();
        console.log(leadsMapped);

        let leadsWithEmail = leadsMapped.filter(lead => lead.email);

        if (!leadsWithEmail.length) {
            PubSub.publish('show-basic-notification', {
                title: 'Error',
                message: 'No leads with email found'
            });
            return;
        }

        let { message } = await LeadsService.uploadLeadsUsingJSON(leadsWithEmail)

        PubSub.publish('show-basic-notification', {
            title: 'Success, leads uploaded',
            message: message
        });

    }

    async function assignLeads() {

        let confirmMessage = 'Are you sure you want to assign the leads to the sequences and assign the dates to start sending the emails?';

        if (window.confirm(confirmMessage)) {
            let { message } = await SequenceService.assignLeadsToAllSequences();
            PubSub.publish('show-basic-notification', {
                title: 'Leads assigned to sequences',
                message: message
            });
        }

    }

    return (
        <div className="container">
            <h2 className="mt-4">Admin Leads</h2>
            <div className='small'>
                Leads available: <span className='text-danger bold'>{leadsCount}</span>
            </div>
            <hr />

            <div className="row">
                <div className="col-4">

                    <h4 className="mb-3">Instructions to upload leads</h4>

                    <p className='small'>Please use the following template to fill the leads data that you want to upload</p>

                    <div className='mb-3'>
                        <a
                            target="_blank"
                            rel="noreferrer"

                            href="https://docs.google.com/spreadsheets/d/1igqND-RBpMiugcFK0rXyV0Jff7unnx8wa1QsbbhEgGQ/edit#gid=0">
                            Leads Template
                        </a>
                    </div>


                    <p className='small'>
                        After you fill the rows, please copy and paste them here in the following textarea
                    </p>

                    <hr />

                    <p className='small'>
                        Or if you prefer you can upload a CSV file with the leads data
                    </p>

                    <input type="file"
                        className='form-control'
                        onChange={(e) => {
                            let file = e.target.files[0];
                            let reader = new FileReader();
                            reader.onload = function (e) {
                                setLeadsText(e.target.result);
                            }
                            reader.readAsText(file);
                        }}
                    />



                    <p className='small mt-3'>
                        <i>The leads data will be uploaded to the database and will be available to use in the app</i>
                    </p>




                </div>

                <div className="col-8">

                    <textarea
                        rows="10"
                        value={leadsText}
                        onChange={(e) => setLeadsText(e.target.value)}
                        className="form-control fs-14">
                    </textarea>
                </div>

            </div>


            <button
                onClick={uploadLeads}
                disabled={!leadsText}
                className="btn btn-success mt-3">
                Upload Leads
            </button>
            <hr className='my-5' />

            <div>
                <h4>Additional options</h4>


                <div className='text-muted small'>
                    Click the button below to assign the leads to the sequences and assign the dates to start sending the emails
                </div>
                <button
                    onClick={assignLeads}
                    className="btn btn-primary mt-3">
                    Assign leads and dates
                </button>
            </div>

            <br />
            <br />

        </div>
    )

}

export default AdminLeads;