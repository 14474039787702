import React, { useState, useEffect } from 'react';
import AuthService from '../Services/AuthService';
import { Tooltip } from 'react-tooltip';


function Login() {
    const [isChecked, setIsChecked] = useState(false);
    const [registerUrl, setRegisterUrl] = useState('');

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const getRegisterUrl = async () => {
        let { url } = await AuthService.getRegisterUrl();
        setRegisterUrl(url);
    }

    const openRegisterUrl = () => {
        window.open(registerUrl, '_self');
    }

    useEffect(() => {
        getRegisterUrl();
    }, [])


    return (
        <div className="login-container">
            <div className="login-header mt-5">
                <h3><span class="badge bg-ulysses">Login</span></h3>
                <h1 className='bold h1-header'>Login for Ulysses —
                    Welcome</h1>
                <p className='mt-3'>Find, contact, and close your ideal buyers with over 260M contacts and streamlined engagement workflows powered by AI.</p>
            </div>

            <button
                className="btn btn-transparent" onClick={openRegisterUrl}>
                <i className="fab fa-google me-2"></i> Log in with Google
                <i className="fas fa-arrow-right ms-2"></i>
            </button>

            <Tooltip id="login-tooltip" />
        </div>
    );
}


export default Login;