import { useEffect, useState } from "react";
import UserService from "../Services/UserService";
import PubSub from "pubsub-js";
import { Link, useParams } from "react-router-dom";
import AdminProfile from "./AdminProfile";
import AdminUserSettings from "./AdminUserSettings";

function UserDetail() {

    const { id } = useParams();
    const [user, setUser] = useState({});

    useEffect(() => {
        getUserDetail();
    }, []);

    async function getUserDetail() {

        let user = await UserService.getUserById(id);
        setUser(user);

    }

    return (
        <div className="container">

            <nav className="divider-breadcrumb mt-3 fs-13" aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to="/admin/users">Users</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{user.name}</li>
                </ol>
            </nav>

            <div className="row mt-5" >

                <div className="col-6">
                    <AdminProfile />

                </div>

                <div className="col-6">
                    <AdminUserSettings />
                </div>

            </div>
        </div>
    )

}

export default UserDetail;