import moment from 'moment';
import { NavLink, useParams } from 'react-router-dom';
import { useInboxCompose } from './InboxComposeProvider';
import InboxService from '../Services/InboxService';
import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';


function InboxEmailDetail() {

    const { id } = useParams();

    const [thread, setThread] = useState({
        messages: []
    });

    const [emailTo, setEmailTo] = useState([]);

    const [replyContent, setReplyContent] = useState('');

    useEffect(() => {
        getMessagesForThread()
    }, [id])

    function openInlineCompose(email, i) {
        email.compose = true;
        thread.messages[i] = email;
        setThread({ ...thread })
    }

    function closeInlineCompose(email, i) {
        email.compose = false;
        thread.messages[i] = email;
        setThread({ ...thread })
    }

    async function getMessagesForThread() {
        let thread = await InboxService.getMessagesByThreadId(id)
        setThread(thread)
    }

    function getParamsForReplyEmail(email) {

        let to = emailTo.map(e => e.value).join(',');

        let params = {
            from: email.account,
            to: to,
            subject: email.subject,
            body: replyContent,
            threadId: email.thread_id
        };
        return params;
    }

    async function sendReplyEmail(email) {

        let params = getParamsForReplyEmail(email);
        let { message } = await InboxService.sendReplyEmail(params);

        alert(message);
        await getMessagesForThread();

    }


    function renderEmail(email, i) {
        return (<div className='mt-2' key={i}>
            <div className='d-flex justify-content-between my-3'>

                <div className="text-muted fs-12 d-flex">
                    <img className='inbox-profile-img' src="https://lh3.googleusercontent.com/a/default-user=s80-p" alt="" />
                    <div className='ms-3'>
                        <div className='bold'>{email.from_formatted}</div>
                        <div className='bold'>{email.to}</div>
                    </div>

                </div>
                <div className="text-muted fs-12">{moment(email.internal_date).format('MMM DD, yyyy, hh:mm a')}</div>
            </div>
            <div
                className='inbox-email-content'
                dangerouslySetInnerHTML={{ __html: email.bodyHtml }} />
            <div>
                <button
                    onClick={() => openInlineCompose(email, i)}
                    className="inbox-reply-button">
                    <i className="fa-solid fa-reply me-2"></i>
                    Reply
                </button>
                <button className="inbox-reply-button">
                    <i className="fa-solid fa-share me-2"></i>
                    Forward
                </button>
            </div>
            <hr />
            {renderComposeReply(email, i)}

        </div>)
    }

    function canSendReplyMessage() {
        return replyContent.length > 0 && emailTo.length > 0;
    }

    function renderComposeReply(email, i) {

        let options = [];

        let email_to = email.from;

        if (email.from === email.account) {
            email_to = email.to;
        }

        let emails = Array.from(new Set(thread.recipients));
        for (let e of emails) {
            options.push({ value: e, label: e });
        }

        if (email.compose !== true) {
            return null;
        }

        return (
            <div
                className="inbox-reply-container">

                <div className='inbox-reply-header'>
                    <span>To:</span>
                    <CreatableSelect
                        isMulti
                        styles={{
                            flex: 1,
                            control: (styles) => ({ ...styles, backgroundColor: 'white', border: 'none', boxShadow: 'none' }),
                        }}
                        onChange={e => {
                            console.log(e);
                            setEmailTo(e);
                        }}
                        defaultValue={[{ value: email_to, label: email_to }]}
                        placeholder="type email address"
                        options={options} />
                </div>
                <div
                    contentEditable="true"
                    onInput={e => setReplyContent(e.target.innerHTML)}
                    className='inbox-compose-reply'>
                </div>

                <div className="inbox-reply-footer">
                    <button
                        disabled={!canSendReplyMessage()}
                        onClick={() => sendReplyEmail(email)}
                        className="inbox-compose-send-button">
                        Send
                    </button>

                    <button
                        onClick={() => closeInlineCompose(email, i)}
                        className='inbox-button-transparent'
                        data-tooltip-id="inbox-tooltip"
                        data-tooltip-content="Discard"
                    >
                        <i className="fa-regular fa-trash-can"></i>
                    </button>
                </div>
            </div>
        )

    }

    function renderEmails() {
        return (<div>
            {thread.messages.map((email, i) => renderEmail(email, i))}

        </div>)
    }


    return (
        <div className='inbox-detail-container'>

            <div className='inbox-detail-buttons'>
                <NavLink
                    data-tooltip-id="inbox-tooltip"
                    className="inbox-button"
                    to="/admin/inbox"
                    data-tooltip-content="Back to inbox"
                >
                    <i className="fa-solid fa-arrow-left"></i>
                </NavLink>


                <button
                    data-tooltip-id="inbox-tooltip"
                    className="inbox-button"
                    data-tooltip-content="Archive"
                >
                    <i className="fa-solid fa-box-archive"></i>
                </button>

                <button
                    data-tooltip-id="inbox-tooltip"
                    className="inbox-button"
                    data-tooltip-content="Delete"
                >
                    <i className="fa-solid fa-trash-can"></i>
                </button>



            </div>

            <div className='inbox-detail-subject'>
                {thread.subject}
            </div>


            <div>
                {renderEmails()}
            </div>



        </div>
    )

}

export default InboxEmailDetail;