import { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink, useParams } from 'react-router-dom';
import SequenceService from '../Services/SequenceService';
import PubSub from 'pubsub-js';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import AuthService from '../Services/AuthService';
import React from 'react';

function SequenceNav() {

    const { id } = useParams();

    const [sequence, setSequence] = useState({
        name: 'loading...',
        user: {}
    });

    const [user, setUser] = useState({});

    const [lastExecution, setLastExecution] = useState({});


    async function getInitialData() {
        getSequenceDetail();
        getLatestSequenceExecution();
        getCurrentUser();
    }

    async function getCurrentUser() {
        let user = await AuthService.getCurrentUser();
        setUser(user);
    }

    async function getLatestSequenceExecution() {
        let lastExecution = await SequenceService.getLatestSequenceExecution(id);
        setLastExecution(lastExecution);
    }

    async function getSequenceDetail() {
        let sequence = await SequenceService.getSequenceDetail(id);
        setSequence(sequence);
    }

    useEffect(() => {
        getInitialData();

        let latestExecutionInterval = setInterval(() => {
            getLatestSequenceExecution();
        }, 15 * 1000);

        return () => {
            clearInterval(latestExecutionInterval);
        }
    }, []);


    function pauseUnPauseSequence() {
        sequence.isActive = !sequence.isActive;
        SequenceService.updateSequence(sequence);
        setSequence({ ...sequence, isActive: sequence.isActive });
        PubSub.publish('show-basic-notification', {
            title: 'Campaign updated',
            message: `Campaign has been ${sequence.isActive ? 'activated' : 'deactivated'}`
        });
    }

    async function startSequence() {
        if (window.confirm('Are you sure you want to start this sequence? Running execution will be stopped.')) {
            let { message } = await SequenceService.startSequenceTimer(id);
            PubSub.publish('show-basic-notification', {
                title: 'Sequence started',
                message,
            });
        }
    }

    async function stopSequence() {
        if (window.confirm('Are you sure you want to stop this sequence?')) {
            let { message } = await SequenceService.stopSequenceTimer(id);
            PubSub.publish('show-basic-notification', {
                title: 'Sequence stopped',
                message,
            });
        }
    }

    function showSequenceStatus() {

        if (lastExecution.status === 'completed') {
            return (<p className="fs-14">
                <span className="badge bg-primary me-2">Completed</span>
                <span className="text-muted">
                    {lastExecution.description}
                </span>
            </p>)
        } else if (lastExecution.status === 'failed') {
            return (<p className="fs-14">
                <span className="badge bg-danger me-2">Failed</span>
                <span className="text-muted">
                    {lastExecution.description}
                </span>
            </p>)

        } else if (lastExecution.status === 'running') {

            return (<p className="fs-14">
                <span className="badge bg-success me-2">Running</span>
                <span className="text-muted">
                    <i className="fa-solid fa-spinner fa-spin me-2"></i>
                    {lastExecution.description} - <i
                        data-tooltip-id="sequence-nav-tooltip"
                        data-tooltip-content={moment(lastExecution.updated_at).fromNow()}
                        className='fs-12 pointer'>last activity at {moment(lastExecution.updated_at).format('ll LTS')}</i>
                </span>
            </p>)

        } else if (lastExecution.status === 'stopped') {
            return (<p className="fs-14">
                <span className="badge bg-danger me-2">Stopped</span>
                <span className="text-muted">
                    {lastExecution.description}
                    <i
                        data-tooltip-id="sequence-nav-tooltip"
                        data-tooltip-content={moment(lastExecution.updated_at).fromNow()}
                        className='fs-12 ms-2 pointer'>
                        stopped at {moment(lastExecution.updated_at).format('ll LTS')}
                    </i>
                </span>
            </p>)
        } else {
            return (<p className="fs-14">
                <span className="text-muted">
                    No executions yet
                </span>
            </p>)
        }

    }


    return (
        <div>
            <div className="container">
                <nav className="divider-breadcrumb mt-3 fs-13" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            {user.role === 'admin' ? (
                                <Link to="/admin/sequences">Campaigns</Link>
                            ) : (
                                <Link to="/sequences">Campaigns</Link>
                            )}

                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{sequence.name}</li>
                    </ol>
                </nav>

                <p className="bold fs-16">{sequence.name} ({sequence.user.name})</p>
                {showSequenceStatus()}


                <Nav variant="underline" defaultActiveKey="/" className="mb-3 mt-4">



                    <Nav.Item>
                        <NavLink
                            end
                            className="fs-14 py-3 sequence-nav-item" to={`/sequences/${id}`}>
                            <i className="fas fa-th me-2"></i>
                            Overview
                        </NavLink>

                    </Nav.Item>
                    <Nav.Item>
                        <NavLink className="fs-14 py-3 sequence-nav-item" to={`/sequences/${id}/emails`}>
                            <i className="far fa-envelope me-2"></i>
                            Emails
                        </NavLink>
                    </Nav.Item>

                    {(user.debug === true || user.role === 'admin') && (
                        <React.Fragment>

                            <Nav.Item >
                                <NavLink className="fs-14 py-3 sequence-nav-item" to={`/sequences/${id}/contacts`}>
                                    <i className="fas fa-users me-2"></i>
                                    Contacts
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item >
                                <NavLink className="fs-14 py-3 sequence-nav-item" to={`/sequences/${id}/logs`}>
                                    <i className="fas fa-history me-2"></i>
                                    Email Logs
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item>
                                <NavLink className="fs-14 py-3 sequence-nav-item" to={`/sequences/${id}/sequence-logs`}>
                                    <i className="fas fa-history me-2"></i>
                                    Seq. Logs
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item >
                                <NavLink className="fs-14 py-3 sequence-nav-item" to={`/sequences/${id}/settings`}>
                                    <i className="fas fa-cogs me-2"></i>
                                    Settings
                                </NavLink>
                            </Nav.Item>

                            <Nav.Item >
                                <NavLink className="fs-14 py-3 sequence-nav-item" to={`/sequences/${id}/schedule`}>
                                    <i className="far fa-calendar me-2"></i>
                                    Schedule
                                </NavLink>
                            </Nav.Item>
                        </React.Fragment>
                    )}





                    <button
                        style={{ marginLeft: 'auto' }}
                        onClick={getInitialData}
                        className='btn btn-secondary btn-sm'>
                        <i className="fas fa-sync"></i>
                    </button>

                    {sequence && sequence.isActive === true ?
                        (
                            <button className='btn btn-danger btn-sm'
                                onClick={pauseUnPauseSequence}  >
                                <i className="fas fa-stop me-2"></i>
                                Stop Campaign
                            </button>
                        ) :
                        (
                            <button className='btn btn-primary btn-sm'
                                onClick={pauseUnPauseSequence}  >
                                <i className="fas fa-play me-2"></i>
                                Start Campaign
                            </button>
                        )
                    }






                </Nav>


            </div>
            <Tooltip id="sequence-nav-tooltip" />
        </div>

    )
}

export default SequenceNav;