import { NavLink, useLocation } from "react-router-dom";
import InboxService from "../Services/InboxService";
import { useEffect, useState } from "react";
import InboxThreadRow from './InboxThreadRow';

function InboxGeneral() {

    const [emails, setEmails] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const location = useLocation();
    const [selectedAccount, setSelectedAccount] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getThreadsCountForInbox();
        getThreadsForInbox();
    }, []);

    useEffect(() => {
        getThreadsCountForInbox();
        getThreadsForInbox();
    }, [selectedAccount]);

    useEffect(() => {
        getThreadsForInbox();
    }, [page]);

    useEffect(() => {
        getAccountFromQueryParams();
    }, [location.search])

    async function getThreadsCountForInbox() {
        let { count } = await InboxService.getThreadCountForInbox('', selectedAccount);
        setCount(count);
    }

    function getAccountFromQueryParams() {
        let params = new URLSearchParams(location.search);
        let account = params.get('account');
        setSelectedAccount(account || '');
    }

    async function getThreadsForInbox() {
        setLoading(true);
        let emails = await InboxService.getThreadMessagesForInbox(page, '', selectedAccount);
        setLoading(false);
        setEmails(emails);
    }

    function incrementPage() {
        if (isLastPage()) return;
        setPage(page + 1);
    }

    function isLastPage() {
        let pages = Math.ceil(count / 10);
        return page === pages;
    }

    function decrementPage() {
        if (page === 1) return;
        setPage(page - 1);
    }


    function renderEmailRow(email, i) {

        return <InboxThreadRow email={email} key={i} />
    }

    return (
        <div>
            <div className='inbox-email-header'>
                <div
                    data-tooltip-id="inbox-tooltip"
                    data-tooltip-content="Select"
                    className="inbox-email-checkbox">
                    <input className="form-check-input"
                        type="checkbox" id={`inbox-email-checkbox`} value={true} />
                </div>

                <button
                    data-tooltip-id="inbox-tooltip"
                    data-tooltip-content="Refresh"
                    className="inbox-button">
                    <i className="fa-solid fa-rotate-right"></i>
                </button>

                <button
                    data-tooltip-id="inbox-tooltip"
                    data-tooltip-content="More"
                    className="inbox-button">
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                </button>

                {loading && (<div className='fs-12 text-muted'>
                    <i class="fa-solid fa-spin fa-spinner me-2"></i>
                    loading...
                </div>)}

                <div className='fs-12 ml-auto me-3'>
                    {InboxService.normalizePaginationText(page, count)}
                </div>

                <button
                    onClick={decrementPage}
                    data-tooltip-id="inbox-tooltip"
                    data-tooltip-content="Newer"
                    className="inbox-button">
                    <i class="fa-solid fa-chevron-left"></i>
                </button>

                <button
                    onClick={incrementPage}
                    data-tooltip-id="inbox-tooltip"
                    data-tooltip-content="Older"
                    disabled={isLastPage()}
                    className="inbox-button">
                    <i class="fa-solid fa-chevron-right"></i>
                </button>


            </div>
            <div className="inbox-email-rows">
                <div class="list-group" style={{ position: 'relative', width: "100%" }}>
                    {emails.map((email, i) => (renderEmailRow(email, i)))}
                </div>
            </div>

        </div >
    )

}

export default InboxGeneral;