const Requests = {
    get(url) {
        return fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .catch(error => this.handleRequestError(error));
    },
    getWithAuth(url) {
        let access_token = localStorage.getItem('access_token');

        return fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            }
        })
            .then(response => this.handleResponse(response))
            .catch(error => this.handleRequestError(error));

    },
    post(url, data) {
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .catch(error => this.handleRequestError(error));

    },
    postWithAuth(url, data) {
        let access_token = localStorage.getItem('access_token');

        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            },
            body: JSON.stringify(data)
        })
            .then(response => this.handleResponse(response))
            .catch(error => this.handleRequestError(error));

    },
    handleResponse(response) {
        if (response.status === 401) {
            // localStorage.removeItem('access_token');
            if(window.location.pathname !== '/login' && window.location.pathname !== '/signin' && window.location.pathname !== '/auth/google/callback') {
                window.location.href = '/signin';
            }
        }

        return response.json();
    },
    handleRequestError(err) {
        console.log(`Error in request`, err);

        return err;
    }
};

export default Requests;