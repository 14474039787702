import { useNavigate } from "react-router-dom";
import AuthService from "../Services/AuthService";
import { useEffect } from 'react';
import PubSub from "pubsub-js";

function RedirectCode() {

    const navigate = useNavigate();



    const getUserFromCode = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        let response = await AuthService.getUserFromCode(code);

        if (!response.access_token) {
            alert('Something went wrong. Message: ' + response.message);
            navigate('/signin');
            return;
        }

        AuthService.saveAccessToken(response.access_token);

        PubSub.publish('login.success');
        // Redirect to home
        navigate('/');
    }

    useEffect(() => {
        getUserFromCode();
    }, []);



    return (
        <div className="login-container">
            <div className="login-header mt-5">
                <h3><span className="badge bg-ulysses">Redirecting...</span></h3>
                <h1 className='bold h1-header'>Redirecting to home</h1>
                <p className='mt-3'>Find, contact, and close your ideal buyers with over 260M contacts and streamlined engagement workflows powered by AI.</p>
            </div>

        </div>
    );

}

export default RedirectCode;