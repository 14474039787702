import { useEffect, useState } from "react";
import PubSub from "pubsub-js";
import UserService from "../Services/UserService";
import { useNavigate } from "react-router-dom";

function UserNew() {

    const navigate = useNavigate();

    const [user, setUser] = useState({
        name: '',
        email: '',
    });

    useEffect(() => {
    }, []);


    async function createUserFromAdmin() {
        let response = await UserService.createUserFromAdmin(user);
        let { success, message } = response;
        if (success) {
            PubSub.publish('show-basic-notification', {
                title: 'Success',
                message: `User has been created`
            });

            navigate(`/admin/users/detail/${response.user._id}`)

        } else {
            PubSub.publish('show-basic-notification', {
                title: 'Error',
                message: message
            });
        }

    }


    return (
        <div className="container">

            <div className="row mt-5" >
                <div className="col-6">
                    <div className="card">
                        <div className="card-header">
                            New user
                        </div>

                        <div className="card-body">

                            <div className="form-group">
                                <label className="fs-13 bold">Email</label>
                                <input type="text"
                                    value={user.email}
                                    onChange={e => setUser({ ...user, email: e.target.value })}
                                    className="form-control form-control-sm" />
                            </div>

                            <div className="form-group">
                                <label className="fs-13 bold">Full name</label>
                                <input type="text"
                                    value={user.name}
                                    onChange={e => setUser({ ...user, name: e.target.value })}
                                    className="form-control form-control-sm" />
                            </div>


                            <div className="form-group mt-4">

                                <button
                                    disabled={!user.name || !user.email}
                                    onClick={createUserFromAdmin}
                                    className="btn btn-primary btn-sm">
                                    Create user
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default UserNew;