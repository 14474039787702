import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Trumbowyg from 'react-trumbowyg';
import SequenceService from '../Services/SequenceService';
import $ from 'jquery';
import UtilsService from '../Services/UtilsService';
import { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import { DEFAULT_VARIABLES_FOR_PREVIEW, EDITOR_BUTTONS } from '../constants';
import UserService from '../Services/UserService';
import AuthService from '../Services/AuthService';
import moment from 'moment';

const EDITOR_ID = 'react-trumbowyg';



function SequenceEmailOffCanvas({ show, onClose, selectedEmailSequence }) {

    const [preview, setPreview] = useState('');

    const [user, setUser] = useState({});

    console.log('(info) - SequenceStepOffcanvas, params', show, onClose, selectedEmailSequence);

    useEffect(() => {
        console.log('useEffect', show);

        console.log('selectedEmailSequence', selectedEmailSequence);


    }, [show]);



    function handleClose() {
        onClose();
    }

    function replaceBodyWithUserProfile(replacedBody, user) {

        if (user && user.name) {
            let first_name = user.name.split(' ')[0];

            let { profile } = user;

            if (profile) {
                let { profile_url, city, country, background } = profile;

                if (profile_url) {
                    replacedBody = replacedBody.replace(new RegExp(`{{profile_url}}`, 'g'), user.profile.profile_url);
                }

                if (background) {
                    replacedBody = replacedBody.replace(new RegExp(`{{background}}`, 'g'), user.profile.background);
                }

                if (city) {
                    replacedBody = replacedBody.replace(new RegExp(`{{city}}`, 'g'), user.profile.city);
                }

                if (country) {
                    replacedBody = replacedBody.replace(new RegExp(`{{country}}`, 'g'), user.profile.country);
                }
            }


            replacedBody = replacedBody.replace(new RegExp(`{{user_fullname}}`, 'g'), user.name);
            replacedBody = replacedBody.replace(new RegExp(`{{user_name}}`, 'g'), user.name);
            replacedBody = replacedBody.replace(new RegExp(`{{user_first_name}}`, 'g'), first_name);
            replacedBody = replacedBody.replace(new RegExp(`{{account_first_name}}`, 'g'), first_name);
        }

        return replacedBody;
    }

    function generatePreview() {

        let html = $(`#${EDITOR_ID}`).trumbowyg('html');

        console.log('(info) - generating preview...');

        let replacedBody = html;
        // let replacedSubject = selectedStep.subject;
        let replacedSubject = '';


        replacedBody = replaceBodyWithUserProfile(replacedBody, user);

        for (let key in DEFAULT_VARIABLES_FOR_PREVIEW) {
            let value = DEFAULT_VARIABLES_FOR_PREVIEW[key];
            replacedBody = replacedBody.replace(new RegExp(`{{${key}}}`, 'g'), value);
            replacedSubject = replacedSubject.replace(new RegExp(`{{${key}}}`, 'g'), value);
        }



        let preview = `
        <div><b>To:</b> Example Contact <example@google.com> </div>
        <div><b>Subject:</b> ${replacedSubject}</div>
        <div><br /></div>
        ${replacedBody}`;

        setPreview(preview);
    }

    async function updateSequenceStep(step) {

        let html = $(`#${EDITOR_ID}`).trumbowyg('html');
        step.body = html;
        step.textBody = UtilsService.convertHtmlToText(html);

        await SequenceService.updateSequenceStep(step);

        PubSub.publish('show-basic-notification', { title: 'Success', message: 'Step saved successfully' });
        PubSub.publish('refresh.sequence.detail');
    }


    async function initializeEditor() {
        await UtilsService.waitForElement(`#${EDITOR_ID}`);
        console.log('initializeEditor');
        UtilsService.convertParagraphsToDivs(EDITOR_ID)

    }



    async function listenForEditorChanges() {
        await UtilsService.waitForElement(`#${EDITOR_ID}`);
        generatePreview();
        $(`#${EDITOR_ID}`).on('tbwchange', function () {
            generatePreview();
        });

    }

    function getClassForStepStatus(step) {
        if (step.email_log && step.email_log.sent_result === 'email sent') {
            return 'bg-success';
        } else if (step.email_log && step.email_log.sent_result !== 'email sent') {
            return 'bg-danger';
        } else if (step.status === 'replied') {
            return 'bg-info';
        } else if (step.status === 'bounced') {
            return 'bg-info';
        }
        else {
            return 'bg-warning';
        }
    }

    function renderStepsDetails() {
        if (!selectedEmailSequence) {
            return <div></div>;
        }

        return selectedEmailSequence.steps.map((step, i) => {

            let data = {
                from: selectedEmailSequence.user.email,
                to: selectedEmailSequence.lead.email,
                subject: step.sequence_step.subject,
                body: step.sequence_step.body
            };

            if (step.email_log) {
                data = step.email_log;
            }

            let headerText = 'Pending email to send at: ' + moment(step.estimated_send_at).format('MM/DD/YYYY hh:mm:ss a');

            if (step.email_log) {
                headerText = 'Email sent at: ' + moment(step.email_log.created_at).format('MM/DD/YYYY hh:mm:ss a');
            }

            if (step.status === 'replied') {
                headerText = 'Email replied at: ' + moment(step.replied_at).format('MM/DD/YYYY hh:mm:ss a');
            }

            if (step.status === 'bounced') {
                headerText = 'Email stopped reason: bounced at: ' + moment(step.bounced_at).format('MM/DD/YYYY hh:mm:ss a');
            }

            if (step.status === 'error') {
                headerText = 'Email stopped reason: error at: ' + moment(step.error_at).format('MM/DD/YYYY hh:mm:ss a');
            }

            let replacedBody = UtilsService.replaceTextWithUserAndLeadVariables(data.body, selectedEmailSequence.user, selectedEmailSequence.lead);
            let replacedSubject = UtilsService.replaceTextWithUserAndLeadVariables(data.subject, selectedEmailSequence.user, selectedEmailSequence.lead);

            return (
                <div className="card bg-white card-shadow mb-3">

                    <div className={"card-header text-white " + getClassForStepStatus(step)}>
                        <div className={'bold small'}>
                            Step {i + 1} - {headerText}
                        </div>
                    </div>
                    <div className="card-body fs-13">
                        <div className='mb-3'>
                            <div>
                                <b>ID: </b>
                                {step._id}
                            </div>
                            <div>
                                <b>From: </b>
                                {data.from}
                            </div>
                            <div><b>To</b>: {data.to} </div>
                            <div><b>Subject</b>: {replacedSubject} </div>
                        </div>
                        <div>
                            <b>Content:</b> <div dangerouslySetInnerHTML={{ __html: replacedBody }}></div>
                        </div>

                    </div>

                </div>


            )

        });
    }


    return (
        <Offcanvas
            backdrop={false}
            style={{ width: '100vw', top: '55px' }}
            placement="end"
            show={show}
            onHide={onClose}
        >
            <Offcanvas.Header>
                <Offcanvas.Title className="d-flex justify-content-between w-100">
                    <button
                        onClick={handleClose}
                        className="btn btn-secondary btn-sm">Cancel</button>

                    <div>


                    </div>


                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="sequence-detail-container">

                {renderStepsDetails()}



            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default SequenceEmailOffCanvas;