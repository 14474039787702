import ModalService from "../Services/ModalService";
import { MODAL_NEW_SEQUENCE } from "../constants";

function NewSequence() {

    function openPrebuiltTemplatesModal() {
        ModalService.openPrebuiltTemplatesModal();
        ModalService.closeNewSequenceModal();
    }

    return (
        <div>
            <div className="modal fade" id={MODAL_NEW_SEQUENCE} tabIndex="-1" aria-labelledby={MODAL_NEW_SEQUENCE} aria-hidden="true">
                <div className="modal-dialog" style={{ maxWidth: 600 }}>
                    <div className="modal-content">

                        <div className="modal-new-sequence-body">
                            <h5 className="bold">Create a sequence</h5>
                            <p className="small">
                                Sequences are a series of automated or manual touchpoints and activities, designed to drive deeper engagement with your contacts.
                            </p>

                            <div className="d-flex flex-wrap">
                                <div
                                    onClick={() => openPrebuiltTemplatesModal()}
                                    className="modal-new-sequence-item">
                                    <div className="mb-3">
                                        <i className="far fa-file-alt fa-4x ulysses-primary"></i>
                                    </div>
                                    <div className="bold">
                                        Pre-formatted
                                    </div>
                                    <div className="small">Start with one of our sequence templates</div>
                                </div>


                                <div className="modal-new-sequence-item">
                                    <div className="mb-3">
                                        <i className="far fa-clone fa-4x ulysses-primary"></i>
                                    </div>
                                    <div className="bold">
                                        Clone
                                    </div>
                                    <p className="small">Make a copy of one of your existing sequences</p>
                                </div>


                                <div className="modal-new-sequence-item">
                                    <div className="mb-3">
                                        <i class="far fa-paper-plane fa-4x ulysses-primary"></i>
                                    </div>
                                    <div className="bold">
                                        From scratch
                                    </div>
                                    <p className="small">Create a new sequence from scratch</p>
                                </div>


                            </div>



                        </div>




                    </div>
                </div>
            </div>

        </div>
    )
}

export default NewSequence;