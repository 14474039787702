import { Link, NavLink } from 'react-router-dom';
import Logo from '../assets/imgs/ulysses-leads-wide.png';
import AuthService from '../Services/AuthService';
import { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';


function NavbarAdmin() {

    const [user, setUser] = useState(null);

    const getCurrentUser = async () => {
        let user = await AuthService.getCurrentUser();
        setUser(user);
    }

    function getInitials(name) {
        let names = name.split(' ');
        let initials = '';
        for (let name of names) {
            initials += name[0] || '';
        }
        return initials;
    }

    const logOut = () => {
        if (window.confirm('Are you sure you want to log out?')) {
            AuthService.logout();
            window.location.href = '/signin';
        }

    }

    useEffect(() => {
        getCurrentUser();
    }, []);

    return (
        <nav className='ulysses-navbar-personal'>
            <NavLink to={'/'}>
                <img src={Logo} width={100} alt="" />

            </NavLink>

            <div className='ulysses-navbar-personal-container'>
                <NavLink to={'/'} className='ulysses-navbar-personal-item'>
                    <i className="fas fa-home me-2"></i>
                    Home
                </NavLink>
                <NavLink to={'/search'} className='ulysses-navbar-personal-item'>
                    <i className="fas fa-search me-2"></i>
                    Search
                </NavLink>
                <NavLink to={'/admin/sequences'} className='ulysses-navbar-personal-item'>
                    <i className="far fa-envelope me-2"></i>
                    Campaigns
                </NavLink>
                <NavLink to={'/admin/leads'} className='ulysses-navbar-personal-item'>
                    <i className="far fa-envelope me-2"></i>
                    Leads
                </NavLink>

                <NavLink to={'/admin/users'} className='ulysses-navbar-personal-item'>
                    <i className="far fa-envelope me-2"></i>
                    Users
                </NavLink>

                <NavLink to={'/admin/inbox'} className='ulysses-navbar-personal-item'>
                    <i className="far fa-envelope me-2"></i>
                    Inbox
                </NavLink>
            </div>

            <div className='ulysses-navbar-personal-settings'>

                <Dropdown className="ms-3" style={{ float: 'right' }} >
                    <Dropdown.Toggle variant="secondary"
                        className="no-chevron btn-sm btn-circle" id="dropdown-basic">
                        {user && user.name && getInitials(user.name)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Link className='dropdown-item' to={'/user/profile'}>Your profile</Link>
                        <Dropdown.Item onClick={logOut}>Log out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

            </div>

        </nav>
    );
}

export default NavbarAdmin;