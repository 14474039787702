import React, { useState, useEffect } from 'react';
import AuthService from '../Services/AuthService';
import { Tooltip } from 'react-tooltip';

function Signin() {
    const [isChecked, setIsChecked] = useState(false);
    const [registerUrl, setRegisterUrl] = useState('');

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const getRegisterUrl = async () => {
        let { url } = await AuthService.getRegisterUrl();
        setRegisterUrl(url);
    }

    const openRegisterUrl = () => {
        window.open(registerUrl, '_self');
    }

    useEffect(() => {
        getRegisterUrl();
    }, [])


    return (
        <div className="login-container">
            <div className="login-header mt-5">
                <h3><span class="badge bg-ulysses">Sign up</span></h3>
                <h1 className='bold h1-header'>Sign up for Ulysses —
                    Free Forever</h1>
                <p className='mt-3'>Find, contact, and close your ideal buyers with over 260M contacts and streamlined engagement workflows powered by AI.</p>
            </div>
            <div className="form-check small mb-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    value={isChecked}
                    onChange={handleCheckboxChange}
                    id="defaultCheck1"
                />
                <label className="form-check-label" htmlFor="defaultCheck1">
                    By signing up, I agree to Ulysses's 
                    <a className='link-bold mx-1' href='https://ulyssesleads.com/terms-and-conditions/' target='_blank' rel='noreferrer'>Terms of Service</a>
                     and 
                     <a className='link-bold mx-1' href='https://ulyssesleads.com/privacy-policy/' target='_blank' rel='noreferrer'>Privacy Policy.</a>
                </label>
            </div>

            <button
                data-tooltip-id="login-tooltip"
                data-tooltip-content="You need to agree to the Terms of Service and Privacy Policy!"
                disabled={!isChecked} className="btn btn-transparent" onClick={openRegisterUrl}>
                <i className="fab fa-google me-2"></i> Sign in with Google
                <i className="fas fa-arrow-right ms-2"></i>
            </button>

            <Tooltip id="login-tooltip" />
        </div>
    );
}


export default Signin;