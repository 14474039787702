import { useEffect, useState } from "react";
import InboxService from "../Services/InboxService";
import { useInboxCompose } from "./InboxComposeProvider";
import CreatableSelect from 'react-select/creatable';

function InboxCompose() {

    const { subject, setSubject } = useInboxCompose();
    const { from, setFrom } = useInboxCompose([]);
    const { to, setTo } = useInboxCompose([]);

    const [body, setBody] = useState('');

    const { isVisible, closeCompose } = useInboxCompose();
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        getAccountsWithInbox();
    }, [])

    if (!isVisible) {
        return null;
    }

    function prepareParamsForSendEmail() {

        let fromRecipients = from.map(f => f.value).join(',');
        let toRecipients = to.map(t => t.value).join(',');

        return {
            from: fromRecipients,
            to: toRecipients,
            subject,
            body
        }
    }

    async function sendEmailFromCompose() {

        let params = prepareParamsForSendEmail();

        let { message } = await InboxService.sendEmail(params)

        alert(message);

    }

    async function getAccountsWithInbox() {
        let accounts = await InboxService.getAccountsWithInbox();
        setAccounts(accounts);
    }

    return (
        <div className="inbox-compose-container">
            <div className="inbox-compose-header">
                New message
                <button
                    data-tooltip-id="inbox-tooltip"
                    data-tooltip-content="Close"
                    onClick={closeCompose}>
                    <i className="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div className="inbox-compose-content">
                <div className="inbox-compose-recipient">
                    <span>From: </span>
                    <CreatableSelect
                        isMulti
                        styles={{
                            flex: 1,
                            control: (styles) => ({ ...styles, backgroundColor: 'white', border: 'none', boxShadow: 'none' }),
                        }}
                        onChange={e => setFrom(e)}
                        defaultValue={[]}
                        placeholder="select an email address"
                        options={accounts.map(acc => ({ value: acc, label: acc }))} />


                </div>
                <div className="inbox-compose-recipient">
                    <span>To: </span>
                    <CreatableSelect
                        isMulti
                        styles={{
                            flex: 1,
                            control: (styles) => ({ ...styles, backgroundColor: 'white', border: 'none', boxShadow: 'none' }),
                        }}
                        onChange={e => setTo(e)}
                        defaultValue={[]}
                        placeholder="select an email address"
                        options={[]} />

                </div>
                <div>
                    <input type="text"
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                        className="inbox-compose-input"
                        placeholder="Subject" />
                </div>
                <div contenteditable="true"
                    onInput={e => setBody(e.target.innerHTML)}
                    className="inbox-compose-body">
                    content
                </div>
                <div>
                    <button
                        onClick={sendEmailFromCompose}
                        className="inbox-compose-send-button">Send</button>
                </div>
            </div>


        </div>
    )
}

export default InboxCompose;