import { useParams } from 'react-router-dom';
import SequenceNav from './SequenceNav';
import EmailSequenceService from '../Services/EmailSequenceService';
import { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';

function SequenceContacts() {

    const { id: sequence_id } = useParams();

    const [emailSequences, setEmailSequences] = useState([]);
    const [total, setTotal] = useState(0);
    const [lastAction, setLastAction] = useState({ index: 0, checked: false });
    const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

    useEffect(() => {
        getEmailSequences();
        handleKeyPressed();

        return () => {
            document.removeEventListener('keydown', () => { });
            document.removeEventListener('keyup', () => { });
        }

    }, []);

    function handleKeyPressed() {

        document.addEventListener('keydown', (e) => {
            if (e.key === 'Shift') {
                setShiftKeyPressed(true);
            }
        });

        document.addEventListener('keyup', (e) => {
            if (e.key === 'Shift') {
                setShiftKeyPressed(false);
            }
        });
    }


    async function getEmailSequences() {
        let { data, total } = await EmailSequenceService.getEmailSequencesBySequenceId(sequence_id);
        setEmailSequences(data);
        setTotal(total);
    }

    function getInitials(name) {
        let names = name.split(' ');
        let initials = '';
        for (let name of names) {
            initials += name[0];
        }
        return initials;
    }

    function getContactsSelectedCount() {
        return emailSequences.filter((emailSequence) => emailSequence.checked).length;
    }

    function onCheckRow(event, index) {
        let checked = event.target.checked;
        let emailSequence = emailSequences[index];
        emailSequence.checked = checked;

        // If the user has shift key pressed, we want to check all the rows in between
        if (shiftKeyPressed) {
            let lastCheckedIndex = lastAction.index;
            let startIndex = lastCheckedIndex < index ? lastCheckedIndex : index;
            let endIndex = lastCheckedIndex > index ? lastCheckedIndex : index;

            for (let i = startIndex; i <= endIndex; i++) {
                emailSequences[i].checked = checked;
            }
        }

        setLastAction({ index, checked });
        setEmailSequences([...emailSequences]);
    }

    async function onRemoveLeads() {
        let leads_id = emailSequences.filter((emailSequence) => emailSequence.checked).map((emailSequence) => emailSequence.lead._id);
        console.log(leads_id);
        if (window.confirm('Are you sure you want to remove these leads from the sequence?')) {
            let { message } = await EmailSequenceService.removeLeadsFromSequence({ sequence_id, leads_id });
            PubSub.publish('show-basic-notification', {
                title: 'Leads removed',
                message: message
            });
            getEmailSequences();
        }
    }

    function renderEmailSequences() {
        return emailSequences.map((emailSequence, i) => {

            let { current_step } = emailSequence;
            let status = 'Active';
            let color = 'primary';

            if (current_step > emailSequence.steps.length) {
                current_step = emailSequence.steps.length;
                status = 'Complete';
                color = 'success';
            }




            return (
                <div key={`email-sequence-${i}`} className='email-sequence-item'>
                    <div className="form-check">
                        <input className="form-check-input"
                            onChange={(e) => onCheckRow(e, i)}
                            checked={emailSequence.checked}
                            type="checkbox" value="" id={'sequence-email-' + i} />
                    </div>

                    <span className="badge bg-primary me-2 ms-2 btn-circle">
                        {getInitials(emailSequence.lead.name)}
                    </span>


                    <span className='text-primary bold fs-15' style={{ width: '300px', maxWidth: '400px' }}>
                        {emailSequence.lead.name}
                    </span>

                    <span className={`badge me-2 bg-${color} ms-2`}>
                        {status}
                    </span>

                    <span className="badge bg-secondary me-2 ms-2">
                        Step {current_step}
                    </span>

                    <span className='fs-13'>
                        {emailSequence.lead.title} @ <span className='text-primary'>{emailSequence.lead.organization_name}</span>
                    </span>



                </div>
            )
        })
    }

    return (
        <div>
            <SequenceNav />

            <div className="container">
                <div className="sequence-detail-container">

                    <div className='d-flex align-items-center mb-2'>
                        <div className='small me-3 bold'>
                            {getContactsSelectedCount()} Contacts selected
                        </div>

                        <button
                            onClick={onRemoveLeads}
                            style={{marginLeft: 'auto'}}
                            disabled={getContactsSelectedCount() === 0}
                            className='btn btn-secondary btn-sm '>
                            Remove leads
                        </button>
                    </div>


                    <div className='email-sequence-contacts-stats'>
                        <div className='sequence-contacts-stats-item active'>
                            <div className='bold'>{total}</div>
                            <div> Total</div>
                        </div>

                    </div>

                    {renderEmailSequences()}
                </div>
            </div>

        </div>
    )
}

export default SequenceContacts;