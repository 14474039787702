import { ARCHIVE_SEQUENCE, ASSIGN_LEADS_TO_ALL_SEQUENCES, ASSIGN_LEADS_TO_SEQUENCE, CREATE_SEQUENCE_FROM_TEMPLATE, GET_ADMIN_SEQUENCES, GET_LATEST_RUNNING_SEQUENCE_EXECUTION, GET_SEQUENCES, GET_SEQUENCES_COUNT, GET_SEQUENCES_LOGS, GET_SEQUENCE_DETAIL, GET_SEQUENCE_SETTINGS, GET_SEQUENCE_TEMPLATES, RESET_EMAIL_SEQUENCE_TO_SEQUENCE, RESET_SEQUENCE_START_DATE, START_SEQUENCE_TIMER, STOP_SEQUENCE_TIMER, UPDATE_SEQUENCE, UPDATE_SEQUENCE_SETTINGS, UPDATE_SEQUENCE_STEP } from "../constants";
import Requests from "../requests";

const SequenceService = {
    getSequenceTemplates() {
        return Requests.getWithAuth(GET_SEQUENCE_TEMPLATES);
    },
    getSequences() {
        return Requests.getWithAuth(GET_SEQUENCES);
    },
    getAdminSequences() {
        return Requests.getWithAuth(GET_ADMIN_SEQUENCES);
    },
    getSequenceDetail(sequence_id) {
        return Requests.getWithAuth(GET_SEQUENCE_DETAIL.replace(':id', sequence_id));
    },
    getSequenceSettings(sequence_id) {
        return Requests.getWithAuth(GET_SEQUENCE_SETTINGS.replace(':id', sequence_id));
    },
    updateSequenceSettings(sequence_id, settings) {
        return Requests.postWithAuth(UPDATE_SEQUENCE_SETTINGS.replace(':id', sequence_id), settings);
    },
    createSequenceFromTemplate(template_id) {
        return Requests.postWithAuth(CREATE_SEQUENCE_FROM_TEMPLATE, { template_id });
    },
    archiveSequence(sequence_id) {
        return Requests.postWithAuth(ARCHIVE_SEQUENCE.replace(':id', sequence_id), { archive: true });
    },
    startSequenceTimer(sequence_id) {
        return Requests.postWithAuth(START_SEQUENCE_TIMER, { sequence_id });
    },
    stopSequenceTimer(sequence_id) {
        return Requests.postWithAuth(STOP_SEQUENCE_TIMER, { sequence_id });
    },
    getSequenceLogs(sequence_id) {
        return Requests.getWithAuth(GET_SEQUENCES_LOGS.replace(':id', sequence_id));
    },
    getSequenceCount() {
        return Requests.getWithAuth(GET_SEQUENCES_COUNT);
    },
    resetSequenceStartDate(sequence_id) {
        return Requests.postWithAuth(RESET_SEQUENCE_START_DATE, { sequence_id });
    },
    assignLeadsToSequence(sequence_id) {
        return Requests.postWithAuth(ASSIGN_LEADS_TO_SEQUENCE, { sequence_id });
    },
    assignLeadsToAllSequences() {
        return Requests.postWithAuth(ASSIGN_LEADS_TO_ALL_SEQUENCES, {});
    },
    resetEmailSequenceForSequence(sequence_id) {
        return Requests.postWithAuth(RESET_EMAIL_SEQUENCE_TO_SEQUENCE, { sequence_id });
    },
    getLatestSequenceExecution(sequence_id) {
        return Requests.getWithAuth(GET_LATEST_RUNNING_SEQUENCE_EXECUTION.replace(':id', sequence_id));
    },
    updateSequence({ _id, name, isActive, max_emails_per_day, max_leads, delay_between_emails, delay_between_emails_max, mode }) {
        return Requests.postWithAuth(UPDATE_SEQUENCE.replace(':id', _id), {
            name,
            mode,
            isActive,
            max_emails_per_day,
            max_leads,
            delay_between_emails,
            delay_between_emails_max
        });
    },
    updateSequenceStep(step) {
        return Requests.postWithAuth(UPDATE_SEQUENCE_STEP.replace(':id', step._id), {
            daysWait: step.daysWait,
            subject: step.subject,
            body: step.body,
            isActive: step.isActive,
            textBody: step.textBody,
        });
    }
}

export default SequenceService;