import { useEffect, useState } from "react";
import SequenceService from "../Services/SequenceService";
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import PubSub from 'pubsub-js';

function AdminSequenceList() {

    const [sequences, setSequences] = useState([]);

    useEffect(() => {
        getAdminSequences();
    }, []);

    async function getAdminSequences() {
        let data = await SequenceService.getAdminSequences();
        setSequences(data);
    }

    function onClickOnActivateSequence(sequence) {
        sequence.isActive = !sequence.isActive;
        SequenceService.updateSequence(sequence);
        setSequences([...sequences]);
        PubSub.publish('show-basic-notification', {
            title: 'Campaign updated',
            message: `Campaign has been ${sequence.isActive ? 'activated' : 'deactivated'}`
        });
    }

    function renderSequences() {
        return sequences.map((sequence, index) => (
            <div className="list-group" key={`sequence${index}`}>
                <div to={'/sequences/' + sequence._id}
                    className="sequence-list-item"
                    aria-current="true">

                    <Link to={'/sequences/' + sequence._id} className="w-100 pointer blank-link">
                        <div className="d-flex w-100 justify-content-between">
                            <p className="mb-1 bold fs-15">{sequence.name}</p>
                        </div>
                        <small>
                            <span className="text-primary"> {sequence.user.name}</span>
                            <span className="mx-1 text-secondary">●</span> {sequence.steps.length} steps
                            <span className="mx-1 text-secondary">●</span> {sequence.leads_id.length} leads
                            <span className="mx-1 text-secondary">●</span> {sequence.leads_available} availables
                        </small>
                    </Link>

                    <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        className="mx-3"
                        onChange={() => onClickOnActivateSequence(sequence)}
                        checked={sequence.isActive}
                        style={{ transform: 'scale(1.3)' }}
                    />

                    {/*
                         <Dropdown>
                        <Dropdown.Toggle variant="muted"
                            className="no-chevron btn-sm" id="dropdown-basic">
                            <i className="fas fa-ellipsis-h"></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => archiveSequence(sequence)}>Archive</Dropdown.Item>
                            <NavLink className='dropdown-item' to={`/sequences/${sequence._id}/settings`}>
                                Edit
                            </NavLink>
                        </Dropdown.Menu>
                    </Dropdown>

                    */}

                </div>

            </div>
        ))
    }


    return (
        <div>
            <div className="ulysses-sequences-title">
                <h5 className="bold">
                    Campaigns
                    (<span className="mx-2 fs-13 text-danger">
                        {sequences.length}
                    </span>)
                </h5>
            </div>

            <div className="ulysses-sequences-container">
                <div className="ulysses-sequences-body">
                    <div className="ulysses-sequences-buttons d-flex">


                    </div>
                    <div className="ulysses-sequences-table">
                        {renderSequences()}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminSequenceList;