import './Inbox.css';
import { Tooltip } from 'react-tooltip';
import { NavLink, useNavigate } from 'react-router-dom';
import InboxSidebar from './InboxSidebar';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import SequenceNav from '../Sequences/SequenceNav';
import InboxGeneral from './InboxGeneral';
import { useState } from 'react';
import InboxEmailDetail from './InboxEmailDetail';
import InboxCompose from './InboxCompose';
import { InboxComposeProvider } from './InboxComposeProvider';
import InboxSearch from './InboxSearch';


function Inbox() {

    const navigate = useNavigate();

    let [search, setSearch] = useState('');


    function onPressEnterKeyInSearch(e) {
        if (e.key === 'Enter') {
            console.log('search for ' + e.target.value);

            if (search) {
                navigate(`/admin/inbox/search/${search}`)

            } else {
                navigate(`/admin/inbox`)
            }

        }
    }


    return (
        <div className="bg-gray vh-100">
            <InboxComposeProvider>
                <div className="container">



                    <div className="inbox-container">

                        <InboxSidebar />


                        <div className="inbox-content">

                            <div className="inbox-search-container">
                                <i className="fa-solid fa-magnifying-glass me-2"></i>
                                <input type="text"
                                    onKeyDown={onPressEnterKeyInSearch}
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                    className="inbox-search-bar"
                                    placeholder="search in mail" />

                                <i class="fa-solid fa-filter"></i>
                            </div>

                            <Routes>
                                <Route path="/" element={<InboxGeneral />} />
                                <Route path="/search/:search" element={<InboxSearch />} />
                                <Route path="/:id" element={<InboxEmailDetail />} />

                            </Routes>

                        </div>

                    </div>

                    <InboxCompose />
                </div>

            </InboxComposeProvider>



            <Tooltip id="inbox-tooltip" />
        </div >
    )

}

export default Inbox;