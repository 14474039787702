import { MODAL_INBOX_COMPOSE, MODAL_NEW_SEQUENCE, MODAL_PREBUILT_TEMPLATES, MODAL_SAVE_LEADS, MODAL_SELECT_INDUSTRIES } from "../constants";

import { Modal } from 'bootstrap';

const ModalService = {
    openNewSequenceModal() {
        var myModal = Modal.getInstance(document.getElementById(MODAL_NEW_SEQUENCE));

        if (!myModal) {
            myModal = new Modal(document.getElementById(MODAL_NEW_SEQUENCE), {});
        }

        myModal.show();
    },
    closeNewSequenceModal() {
        var myModal = Modal.getInstance(document.getElementById(MODAL_NEW_SEQUENCE));

        if (!myModal) {
            myModal = new Modal(document.getElementById(MODAL_NEW_SEQUENCE), {});
        }

        myModal.hide();
    },
    openPrebuiltTemplatesModal() {
        var myModal = Modal.getInstance(document.getElementById(MODAL_PREBUILT_TEMPLATES));

        if (!myModal) {
            myModal = new Modal(document.getElementById(MODAL_PREBUILT_TEMPLATES), {});
        }

        myModal.show();
    },
    openSaveLeadsModal() {
        var myModal = Modal.getInstance(document.getElementById(MODAL_SAVE_LEADS));

        if (!myModal) {
            myModal = new Modal(document.getElementById(MODAL_SAVE_LEADS), {});
        }

        myModal.show();
    },
    openInboxComposeModal() {
        let elements = document.getElementsByClassName(MODAL_INBOX_COMPOSE);

        for (let element of elements) {
            element.style.display = 'block';
        }
    },
    closeInboxComposeModal() {
        let elements = document.getElementsByClassName(MODAL_INBOX_COMPOSE);

        for (let element of elements) {
            element.style.display = 'none';
        }

    },
    closePrebuiltTemplatesModal() {
        var myModal = Modal.getInstance(document.getElementById(MODAL_PREBUILT_TEMPLATES));

        if (!myModal) {
            myModal = new Modal(document.getElementById(MODAL_PREBUILT_TEMPLATES), {});
        }

        myModal.hide();
    },
    openSelectIndustriesModal() {
        var myModal = Modal.getInstance(document.getElementById(MODAL_SELECT_INDUSTRIES));

        if (!myModal) {
            myModal = new Modal(document.getElementById(MODAL_SELECT_INDUSTRIES), {});
        }

        myModal.show();
    },
    closeSelectIndustriesModal() {
        var myModal = Modal.getInstance(document.getElementById(MODAL_SELECT_INDUSTRIES));

        if (!myModal) {
            myModal = new Modal(document.getElementById(MODAL_SELECT_INDUSTRIES), {});
        }

        myModal.hide();
    },
    closeSaveLeadsModal() {
        var myModal = Modal.getInstance(document.getElementById(MODAL_SAVE_LEADS));

        if (!myModal) {
            myModal = new Modal(document.getElementById(MODAL_SAVE_LEADS), {});
        }

        myModal.hide();
    }
}

export default ModalService;