import { useParams } from "react-router";
import SequenceNav from "./SequenceNav";
import { useEffect, useState } from "react";
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import SequenceService from "../Services/SequenceService";

function SequenceLogs() {

    const { id: sequence_id } = useParams();

    const [sequenceLogs, setSequenceLogs] = useState([]);


    useEffect(() => {
        getSequenceLogs();
    }, []);

    async function getSequenceLogs() {
        let logs = await SequenceService.getSequenceLogs(sequence_id);
        setSequenceLogs(logs);
    }

    function renderSequenceLogs() {
        return sequenceLogs.map((sequenceLog, i) => {

            return (
                <div key={`sequence-log-${i}`} className='email-sequence-item'>

                    <div
                        data-tooltip-id="sequence-logs"
                        style={{ minWidth: '120px' }}
                        data-tooltip-content={`${moment(sequenceLog.created_at).fromNow()}`}
                        className="fs-13 me-3">
                        <b>Sent at:</b> {moment(sequenceLog.created_at).format('MM/DD/YYYY hh:mm:ss a')}
                    </div>

                    <div className="fs-13">
                        {sequenceLog.content}
                    </div>

                </div>
            )
        })
    }

    return (
        <div>

            <SequenceNav />
            <div className="container">
                <div className="sequence-detail-container">

                    {renderSequenceLogs()}


                </div>
            </div>

            <Tooltip id="sequence-logs" />
        </div>
    )

}

export default SequenceLogs;