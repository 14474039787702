import { useEffect, useState } from "react";
import UserService from "../Services/UserService";
import PubSub from "pubsub-js";

function UserProfile() {


    const [profile, setProfile] = useState({});

    useEffect(() => {
        getUserProfile();
    }, []);

    async function getUserProfile() {
        let profile = await UserService.getUserProfile();
        setProfile(profile);
    }

    async function updateProfile() {
        await UserService.updateUserProfile(profile);
        showSuccessUpdateProfileNotification();

    }
    function showSuccessUpdateProfileNotification() {
        PubSub.publish('show-basic-notification', {
            title: 'Success',
            message: `Profile has been updated`
        });
    }


    return (
        <div className="container">

            <div className="row mt-5" >
                <div className="col-6">
                    <div className="card">
                        <div className="card-header">
                            Profile info
                        </div>

                        <div className="card-body">

                            <div className="form-group">
                                <label className="fs-13 bold">Full Name</label>
                                <input type="text"
                                    value={profile.fullname}
                                    disabled
                                    className="form-control form-control-sm" />
                            </div>

                            <div className="form-group">
                                <label className="fs-13 bold">First Name</label>
                                <input type="text"
                                    value={profile.firstname}
                                    disabled
                                    className="form-control form-control-sm" />
                            </div>

                            <div className="form-group">
                                <label className="fs-13 bold">Company</label>
                                <input type="text"
                                    value={profile.company}
                                    disabled
                                    className="form-control form-control-sm" />
                            </div>

                            <div className="form-group">
                                <label className="fs-13 bold">City</label>
                                <input type="text"
                                    value={profile.city}
                                    disabled
                                    className="form-control form-control-sm" />
                            </div>

                            <div className="form-group">
                                <label className="fs-13 bold">Country</label>
                                <input type="text"
                                    value={profile.country}
                                    disabled
                                    className="form-control form-control-sm" />
                            </div>

                            <div className="form-group">
                                <label className="fs-13 bold">Requested work</label>
                                <input type="text"
                                    value={profile.requested_work}
                                    disabled
                                    className="form-control form-control-sm" />
                            </div>

                            <div className="form-group">
                                <label className="fs-13 bold">Background</label>
                                <input type="text"
                                    value={profile.background}
                                    disabled
                                    className="form-control form-control-sm" />
                            </div>

                            <div className="form-group">
                                <label className="fs-13 bold">Profile URL</label>
                                <input type="text"
                                    value={profile.profile_url}
                                    disabled
                                    className="form-control form-control-sm" />
                            </div>

                            <div className="form-group mt-4">

                                <button
                                    disabled
                                    onClick={updateProfile}
                                    className="btn btn-primary btn-sm">
                                    Save profile
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default UserProfile;