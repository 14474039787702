import { useEffect, useState } from "react";
import CreatableSelect from 'react-select/creatable';
import LeadsService from "../Services/LeadsService";
import SequenceService from "../Services/SequenceService";
import EmailSequenceService from "../Services/EmailSequenceService";
import ModalService from "../Services/ModalService";
import PubSub from 'pubsub-js';
import { useNavigate } from "react-router-dom";

function SaveLeads({ leadsSelected = [] }) {

    const navigate = useNavigate();

    const [selectedList, setSelectedList] = useState(null);
    const [selectedSequence, setSelectedSequence] = useState(null); // [{value: '', label: ''}
    const [listsNames, setListsNames] = useState([]);
    const [sequences, setSequences] = useState([]);
    const [loading, setLoading] = useState(false);


    async function getLeadListsNames() {
        let names = await LeadsService.getLeadListsNames();
        setListsNames(names);
    }

    async function getSequences() {
        let sequences = await SequenceService.getSequences();
        setSequences(sequences);
    }

    async function saveLeadsIntoList() {
        setLoading(true);
        let leads_id = leadsSelected.map(lead => lead._id);
        await LeadsService.saveLeadsIntoList({ leads_id, name: selectedList.value });
        setLoading(false);
        ModalService.closeSaveLeadsModal();
        PubSub.publish('show-basic-notification', {
            title: 'Leads added',
            message: `Leads added to sequence`
        });

    
    }


    function hideModal() {
        ModalService.closeSaveLeadsModal();
    }

    function getSelectOptions() {
        return listsNames.map(name => ({ value: name, label: name }));
    }

    function getSequenceOptions() {
        return sequences.map(sequence => ({ value: sequence._id, label: sequence.name }));
    }

    async function onAddToSequence() {
        setLoading(true);
        let leads_id = leadsSelected.map(lead => lead._id);
        let sequence_id = selectedSequence.value;

        await EmailSequenceService.createEmailSequenceFromLeadsIds({
            leads_id,
            sequence_id
        });
        
        setLoading(false);
        ModalService.closeSaveLeadsModal();
        PubSub.publish('show-basic-notification', {
            title: 'Leads added',
            message: `Leads added to sequence`
        });
        navigate(`/sequences/${sequence_id}/contacts`);

    }


    function renderLoading() {
        return (loading &&
            <div className="small">
                <i className="fas fa-spinner fa-spin me-2"></i>
                adding leads, please wait...
            </div>
        )

    }
    function saveButtonCondition() {
        return leadsSelected.length > 0 && selectedList && loading === false;
    }

    function saveSequenceButtonCondition() {
        return leadsSelected.length > 0 && selectedSequence && loading === false;
    }

    useEffect(() => {
        getLeadListsNames();
        getSequences();
    }, [])

    return (
        <div>
            <div className="modal fade" id="save-leads-modal" tabIndex="-1" aria-labelledby="save-leads-modal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title bold">Save contacts</h6>
                            <button type="button" className="btn-close fs-12" onClick={() => hideModal()}></button>


                        </div>
                        <div className="modal-save-leads-body">

                            <div className="save-leads-item">
                                <div className="save-leads-title">
                                    Attention: You have <span className="bold text-danger">{leadsSelected.length}</span> leads selected
                                </div>

                            </div>

                            <div className="save-leads-item">
                                <div className="save-leads-title">
                                    {renderLoading()}

                                </div>
                            </div>

                            <div className="save-leads-item">
                                <p className="save-leads-title">
                                    <i className="far fa-list-alt me-3"></i>
                                    Add to lists
                                </p>

                                <div className="save-leads-item-content">

                                    <span className="fs-12 bold">Add to lists</span>
                                    <CreatableSelect className="custom-ng-select"
                                        onChange={setSelectedList}
                                        placeholder="Select or create a list"
                                        isClearable options={getSelectOptions()} />

                                    <button
                                        disabled={!saveButtonCondition()}
                                        onClick={saveLeadsIntoList}
                                        className="btn btn-primary btn-sm fs-12 bold mt-2">
                                        Add to list
                                    </button>
                                </div>

                            </div>

                            <div className="save-leads-item">
                                <p className="save-leads-title">
                                    <i className="far fa-list-alt me-3"></i>
                                    Add to existing sequence
                                </p>

                                <div className="save-leads-item-content">

                                    <span className="fs-12 bold">Add to sequence</span>
                                    <CreatableSelect className="custom-ng-select"
                                        onChange={setSelectedSequence}
                                        placeholder="Select an existing sequence"
                                        isClearable options={getSequenceOptions()} />

                                    <button
                                        onClick={onAddToSequence}
                                        disabled={!saveSequenceButtonCondition()}
                                        className="btn btn-primary btn-sm mt-2 fs-12 bold">
                                        <i className="fas fa-plus me-2"></i>
                                        Add to sequence
                                    </button>
                                </div>

                            </div>



                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={hideModal}
                                className="btn btn-secondary btn-sm fs-13">
                                Cancel
                            </button>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    )

}

export default SaveLeads;