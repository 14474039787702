import $ from 'jquery';
import { DEFAULT_VARIABLES_FOR_PREVIEW } from '../constants';
import moment from 'moment';

const UtilsService = {
    convertHtmlToText(html) {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    },
    convertParagraphsToDivs(editor_id) {
        console.log(`(info) - listening for tbwchange on ${editor_id}`);
        $(`#${editor_id}`).on('tbwchange', function () {
            console.log('tbwchange');
            let editor = document.querySelector(`#${editor_id}`);
            let baseNode = window.getSelection().baseNode;
            if (editor.contains(baseNode) && (baseNode.nodeName === 'P' || baseNode.parentElement.nodeName === 'P')) {
                document.execCommand('formatBlock', false, '<div>');
            }
        });
    },
    waitForElement(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    observer.disconnect();
                    resolve(document.querySelector(selector));
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    },
    async replaceTextWithDefaultVariables(text) {
        let replacedText = text;
        for (let key in DEFAULT_VARIABLES_FOR_PREVIEW) {
            let value = DEFAULT_VARIABLES_FOR_PREVIEW[key];
            replacedText = replacedText.replace(new RegExp(`{{${key}}}`, 'g'), value);
        }

        return replacedText;
    },
    replaceTextWithUserAndLeadVariables(text, user, lead) {
        if (user && user.name) {
            let first_name = user.name.split(' ')[0];

            let { profile } = user;

            if (profile) {
                let { profile_url, city, country, background } = profile;

                if (profile_url) {
                    text = text.replace(new RegExp(`{{profile_url}}`, 'g'), user.profile.profile_url);
                }

                if (background) {
                    text = text.replace(new RegExp(`{{background}}`, 'g'), user.profile.background);
                }

                if (city) {
                    text = text.replace(new RegExp(`{{city}}`, 'g'), user.profile.city);
                }

                if (country) {
                    text = text.replace(new RegExp(`{{country}}`, 'g'), user.profile.country);
                }
            }




            text = text.replace(new RegExp(`{{user_fullname}}`, 'g'), user.name);
            text = text.replace(new RegExp(`{{user_name}}`, 'g'), user.name);
            text = text.replace(new RegExp(`{{user_first_name}}`, 'g'), first_name);
            text = text.replace(new RegExp(`{{account_first_name}}`, 'g'), first_name);
        }

        if (lead) {
            if (lead.name) {
                let lead_first_name = lead.name.split(' ')[0];
                text = text.replace(new RegExp(`{{lead_first_name}}`, 'g'), lead_first_name);

                let lead_fullname = lead.name;
                text = text.replace(new RegExp(`{{lead_fullname}}`, 'g'), lead_fullname);
                text = text.replace(new RegExp(`{{lead_name}}`, 'g'), lead_fullname);
            }

            if (lead.organization_name) {
                text = text.replace(new RegExp(`{{company}}`, 'g'), lead.organization_name);
            }
            if (lead.industry) {
                text = text.replace(new RegExp(`{{industry}}`, 'g'), lead.industry);
            }
        }
        
        return text;
    },
    formatDateForInbox(date) {
        let isToday = moment(date).isSame(moment(), 'day');
        let isThisYear = moment(date).isSame(moment(), 'year');

        if (isToday) {
            return moment(date).format('hh:mm A');
        } else if(isThisYear) {
            return moment(date).format('MMM DD');
        }
        else {
            return moment(date).format('DD/MM/YYYY');
        }
    }

}

export default UtilsService;