import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Trumbowyg from 'react-trumbowyg';
import SequenceService from '../Services/SequenceService';
import $ from 'jquery';
import UtilsService from '../Services/UtilsService';
import { useEffect, useState } from 'react';
import PubSub from 'pubsub-js';
import { DEFAULT_VARIABLES_FOR_PREVIEW, EDITOR_BUTTONS } from '../constants';
import UserService from '../Services/UserService';
import AuthService from '../Services/AuthService';

const EDITOR_ID = 'react-trumbowyg';



function SequenceStepOffcanvas({ show, onClose, selectedStep, setSelectedStep }) {

    const [preview, setPreview] = useState('');

    const [user, setUser] = useState({});

    console.log('(info) - SequenceStepOffcanvas, params', show, onClose, selectedStep);

    useEffect(() => {
        console.log('useEffect', show);
        getCurrentUser();
        if (show && user) {

            initializeEditor();
            listenForEditorChanges();
            console.log($('#react-trumbowyg'));
        }

    }, [show]);

    async function getCurrentUser() {

        let user = await AuthService.getCurrentUser();
        let profile = await UserService.getUserProfile();

        user.profile = profile;

        setUser(user)
    }

    function handleClose() {
        onClose();
    }

    function replaceBodyWithUserProfile(replacedBody, user) {

        if (user && user.name) {
            let first_name = user.name.split(' ')[0];

            let { profile } = user;

            if (profile) {
                let { profile_url, city, country, background, requested_work, first_name } = profile;

                if (profile_url) {
                    replacedBody = replacedBody.replace(new RegExp(`{{profile_url}}`, 'g'), user.profile.profile_url);
                }

                if (background) {
                    replacedBody = replacedBody.replace(new RegExp(`{{background}}`, 'g'), user.profile.background);
                }

                if (city) {
                    replacedBody = replacedBody.replace(new RegExp(`{{city}}`, 'g'), user.profile.city);
                }

                if (country) {
                    replacedBody = replacedBody.replace(new RegExp(`{{country}}`, 'g'), user.profile.country);
                }

                if (requested_work) {
                    replacedBody = replacedBody.replace(new RegExp(`{{requested_work}}`, 'g'), requested_work);
                }

                if(first_name) {
                    replacedBody = replacedBody.replace(new RegExp(`{{account_first_name}}`, 'g'), first_name);
                    replacedBody = replacedBody.replace(new RegExp(`{{user_first_name}}`, 'g'), first_name);
                }
            }


            replacedBody = replacedBody.replace(new RegExp(`{{user_fullname}}`, 'g'), user.name);
            replacedBody = replacedBody.replace(new RegExp(`{{user_name}}`, 'g'), user.name);
            replacedBody = replacedBody.replace(new RegExp(`{{user_first_name}}`, 'g'), first_name);
            replacedBody = replacedBody.replace(new RegExp(`{{account_first_name}}`, 'g'), first_name);
        }

        return replacedBody;
    }

    function generatePreview() {

        let html = $(`#${EDITOR_ID}`).trumbowyg('html');

        console.log('(info) - generating preview...');

        let replacedBody = html;
        let replacedSubject = selectedStep.subject;


        replacedBody = replaceBodyWithUserProfile(replacedBody, user);

        for (let key in DEFAULT_VARIABLES_FOR_PREVIEW) {
            let value = DEFAULT_VARIABLES_FOR_PREVIEW[key];
            replacedBody = replacedBody.replace(new RegExp(`{{${key}}}`, 'g'), value);
            replacedSubject = replacedSubject.replace(new RegExp(`{{${key}}}`, 'g'), value);
        }



        let preview = `
        <div><b>To:</b> Example Contact <example@google.com> </div>
        <div><b>Subject:</b> ${replacedSubject}</div>
        <div><br /></div>
        ${replacedBody}`;

        setPreview(preview);
    }

    async function updateSequenceStep(step) {

        let html = $(`#${EDITOR_ID}`).trumbowyg('html');
        step.body = html;
        step.textBody = UtilsService.convertHtmlToText(html);

        await SequenceService.updateSequenceStep(step);

        PubSub.publish('show-basic-notification', { title: 'Success', message: 'Step saved successfully' });
        PubSub.publish('refresh.sequence.detail');
    }

    async function sendTestEmail() {
        let subject = await UtilsService.replaceTextWithDefaultVariables(selectedStep.subject);
        let body = $(`#${EDITOR_ID}`).trumbowyg('html');
        let bodyReplaced = replaceBodyWithUserProfile(body, user);
        bodyReplaced = await UtilsService.replaceTextWithDefaultVariables(bodyReplaced);

        await UserService.sendTestEmailToMe({ subject, body: bodyReplaced });
        PubSub.publish('show-basic-notification', { title: 'Success', message: 'Test email sent successfully' });
    }

    async function initializeEditor() {
        await UtilsService.waitForElement(`#${EDITOR_ID}`);
        console.log('initializeEditor');
        UtilsService.convertParagraphsToDivs(EDITOR_ID)

    }



    async function listenForEditorChanges() {
        await UtilsService.waitForElement(`#${EDITOR_ID}`);
        generatePreview();
        $(`#${EDITOR_ID}`).on('tbwchange', function () {
            generatePreview();
        });

    }




    return (
        <Offcanvas
            backdrop={false}
            style={{ width: '100vw', top: '55px' }}
            placement="end"
            show={show}
            onHide={onClose}
        >
            <Offcanvas.Header>
                <Offcanvas.Title className="d-flex justify-content-between w-100">
                    <button
                        onClick={handleClose}
                        className="btn btn-secondary btn-sm">Cancel</button>

                    <div>
                        <button
                            onClick={sendTestEmail}
                            className="btn btn-secondary btn-sm me-2">
                            Send test email to me
                        </button>
                        <button
                            onClick={async () => {
                                updateSequenceStep(selectedStep);
                                handleClose();
                            }}
                            className="btn btn-primary btn-sm">
                            Save
                        </button>
                    </div>


                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="sequence-detail-container">

                <div className="card bg-white card-shadow">

                    <div className="card-header bg-white">
                        <div className="bold">Edit template</div>
                    </div>
                    <div className="card-body fs-13">
                        <div className="row">
                            <div className="col-6">

                                <label className="fs-14 bold">Subject</label>
                                <input type="text"
                                    value={selectedStep.subject}
                                    onChange={(e) => {
                                        selectedStep.subject = e.target.value;
                                        setSelectedStep({ ...selectedStep });
                                    }}
                                    className="form-control fs-13" />

                                <Trumbowyg
                                    id={EDITOR_ID}
                                    data={selectedStep.body}
                                    semantic={false}
                                    buttons={
                                        EDITOR_BUTTONS
                                    }

                                    placeholder='Type your text!'
                                />

                                {user && user.role === 'admin' && (
                                    <div>
                                        <div className='bold mb-3'>Addional options:</div>

                                        <div className="row">
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label>Days wait</label>
                                                    <input type="number"
                                                        value={selectedStep.daysWait}
                                                        onChange={(e) => {
                                                            selectedStep.daysWait = e.target.value;
                                                            setSelectedStep({ ...selectedStep });
                                                        }}
                                                        className="form-control fs-13" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}



                            </div>
                            <div className="col-6">
                                <label className="fs-14 bold">Generate Preview for Contact (optional)</label>

                                <select
                                    className="form-select"
                                    name="select-123" placeholder="Choose a contact">
                                        <option value="">Jeb Smith</option>
                                </select>

                                <div className="mt-3 ">
                                    <div className='preview-step-div'
                                        dangerouslySetInnerHTML={{ __html: preview }}>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>





                </div>

            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default SequenceStepOffcanvas;