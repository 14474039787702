import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SequenceNav from "./SequenceNav";
import SequenceService from "../Services/SequenceService";
import PubSub from 'pubsub-js';

import moment from 'moment-timezone';

const timezones = moment.tz.names();

function SequenceSchedule() {

    const { id } = useParams();

    const [settings, setSettings] = useState({});

    useEffect(() => {
        getSequenceSettings();
    }, []);

    async function getSequenceSettings() {
        let settings = await SequenceService.getSequenceSettings(id);
        setSettings(settings);
    }

    async function updateSequenceSettings() {
        await SequenceService.updateSequenceSettings(id, settings);
        PubSub.publish('show-basic-notification', { title: 'Success', message: 'Settings were updated successfully' });
    }

    function renderDays() {
        let days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

        return days.map((day, index) => {
            return (
                <div class="form-check form-check-inline">
                    <input class="form-check-input"
                        onChange={(e) => setSettings({ ...settings, [day]: e.target.checked })}
                        checked={settings[day]}
                        type="checkbox" id={`${day}-checkbox`} value={true} />
                    <label class="form-check-label bold fs-12" for={`${day}-checkbox`}>{day}</label>
                </div>
            )
        });
    }

    return (
        <div>
            <SequenceNav />
            <div className="container">
                <div className="sequence-detail-container">


                    <div className="card">
                        <div className="card-body">

                            <div className="bold fs-16 mb-3">
                                Timing
                            </div>

                            <div className="row mb-4">
                                <div className="col-3">
                                    <label className="bold fs-12">Start time</label>
                                    <input type="text"
                                        value={settings.start_time}
                                        onChange={(e) => setSettings({ ...settings, start_time: e.target.value })}
                                        className="form-control form-control-sm" />
                                </div>
                                <div className="col-3">
                                    <label className="bold fs-12">End time</label>
                                    <input type="text"
                                        value={settings.end_time}
                                        onChange={(e) => setSettings({ ...settings, end_time: e.target.value })}
                                        className="form-control form-control-sm" />
                                </div>
                                <div className="col-6">
                                    <label className="bold fs-12">Timezone</label>
                                    <select
                                        value={settings.timezone}
                                        onChange={(e) => setSettings({ ...settings, timezone: e.target.value })}
                                        className="form-select form-select-sm">
                                        {timezones.map((timezone, index) =>
                                            <option value={timezone}>{timezone}</option>
                                        )}
                                    </select>
                                </div>
                            </div>


                            <button onClick={updateSequenceSettings} className="btn btn-primary btn-sm">
                                Save changes
                            </button>

                        </div>
                    </div>

                    <div className="card mt-4">
                        <div className="card-body">

                            <div className="bold fs-16 mb-3">
                                Days
                            </div>

                            <div className="mb-3">

                                {renderDays()}

                            </div>

                            <button onClick={updateSequenceSettings} className="btn btn-primary btn-sm">
                                Save changes
                            </button>

                        </div>
                    </div>

                    <div className="card mt-4">
                        <div className="card-body">
                            <div className="mt-3 mb-3 fs-16 bold">Delay between emails (in seconds)</div>

                            <div className="row mb-3">
                                <div className="col-3">
                                    <label className="bold fs-12">Min delay</label>
                                    <input type="number"
                                        value={settings.delay_between_emails}
                                        onChange={(e) => setSettings({ ...settings, delay_between_emails: e.target.value })}
                                        className="form-control form-control-sm" />
                                </div>
                                <div className="col-3">
                                    <label className="bold fs-12">Max delay</label>
                                    <input type="number"
                                        value={settings.delay_between_emails_max}
                                        onChange={(e) => setSettings({ ...settings, delay_between_emails_max: e.target.value })}
                                        className="form-control form-control-sm" />
                                </div>

                                <div className="col-3">
                                    <label className="bold fs-12">Min emails per day</label>
                                    <input type="number"
                                        value={settings.min_emails_per_day}
                                        onChange={(e) => setSettings({ ...settings, min_emails_per_day: e.target.value })}
                                        className="form-control form-control-sm" />
                                </div>

                                <div className="col-3">
                                    <label className="bold fs-12">Max emails per day</label>
                                    <input type="number"
                                        value={settings.max_emails_per_day}
                                        onChange={(e) => setSettings({ ...settings, max_emails_per_day: e.target.value })}
                                        className="form-control form-control-sm" />
                                </div>
                            </div>

                            <div className="row mb-4">
                                <div className="col-3">
                                    <label className="bold fs-12">Increment emails per day</label>
                                    <input type="number"
                                        value={settings.increment_emails_per_day}
                                        onChange={(e) => setSettings({ ...settings, increment_emails_per_day: e.target.value })}
                                        className="form-control form-control-sm" />
                                </div>
                            </div>

                            <button onClick={updateSequenceSettings} className="btn btn-primary btn-sm">
                                Save changes
                            </button>
                        </div>
                    </div>

                </div>



            </div>
        </div>
    )

}

export default SequenceSchedule