import { useParams } from "react-router";
import SequenceNav from "./SequenceNav";
import { useEffect, useState } from "react";
import EmailSequenceService from "../Services/EmailSequenceService";
import moment from 'moment';
import UtilsService from "../Services/UtilsService";
import { Tooltip } from 'react-tooltip';
const { convertHtmlToText } = UtilsService;

function SequenceEmailsLogs() {

    const { id: sequence_id } = useParams();

    const [emailLogs, setEmailLogs] = useState([]);


    useEffect(() => {
        getEmailLogs();
    }, []);

    async function getEmailLogs() {
        let logs = await EmailSequenceService.getEmailSequencesLogsBySequenceId(sequence_id);
        setEmailLogs(logs);
    }

    function renderEmailLogs() {
        return emailLogs.map((emailLog, i) => {



            return (
                <div key={`email-log-${i}`} className='email-sequence-item'>
                    <div className="fs-13 me-3">
                        <div><b>From:</b> {emailLog.from}</div>
                        <div><b>To:</b> {emailLog.to}</div>
                    </div>
                    <div className="fs-13">
                        <div className="mb-1"><b>Subject:</b> {emailLog.subject}</div>
                        <div><b>Body:</b> {convertHtmlToText(emailLog.body).substring(0, 300)}...</div>
                    </div>
                    <div
                        data-tooltip-id="sequence-logs"
                        style={{ minWidth: '120px' }}
                        data-tooltip-content={`${moment(emailLog.sent_at).fromNow()}`}
                        className="fs-13 ms-3">
                        <b>Sent at:</b> {moment(emailLog.created_at).format('MM/DD/YYYY hh:mm:ss a')}
                    </div>
                    <div
                        style={{ minWidth: '200px' }}
                        className="fs-13 ms-3">
                        <b>Result:</b> {emailLog.sent_result}
                    </div>
                </div>
            )
        })
    }

    return (
        <div>

            <SequenceNav />
            <div className="container">
                <div className="sequence-detail-container">

                    {renderEmailLogs()}


                </div>
            </div>

            <Tooltip id="sequence-logs" />
        </div>
    )

}

export default SequenceEmailsLogs;