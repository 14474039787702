import logo from './logo.svg';
import './App.css';
import Login from './Login/Login';
import Navbar from './Navbar/Navbar';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Home/Home';
import RedirectCode from './Login/RedirectCode';
import NavbarInternal from './Navbar/NavbarInternal';
import AuthService from './Services/AuthService';
import { useEffect, useState } from 'react';
import Search from './Search/Search';
import Sequences from './Sequences/Sequences';
import SequenceDetail from './Sequences/SequenceDetail';
import BasicNotification from './Notifications/BasicNotification';
import SequenceContacts from './Sequences/SequenceContacts';
import SequenceSettings from './Sequences/SequenceSettings';
import UserProfile from './User/UserProfile';
import SequenceEmails from './Sequences/SequenceEmails';
import PubSub from 'pubsub-js';
import SequenceEmailsLogs from './Sequences/SequenceEmailsLogs';
import SequenceLogs from './Sequences/SequenceLogs';
import NavbarAdmin from './Navbar/NavbarAdmin';
import SequenceSchedule from './Sequences/SequenceSchedule';
import UsersRegistered from './Admin/UsersRegistered';
import AdminProfile from './Admin/AdminProfile';
import AdminLeads from './Admin/AdminLeads';
import UserDetail from './Admin/UserDetail';
import AdminSequenceList from './Sequences/AdminSequenceList';
import Signin from './Login/Signin';
import UserNew from './User/UserNew';
import Inbox from './Inbox/Inbox';

function App() {

  const [user, setUser] = useState(null);

  const getCurrentUser = async () => {
    console.log(window.location.pathname);
    if (window.location.pathname === '/auth/google/callback') {
      return;
    }

    let user = await AuthService.getCurrentUser();
    setUser(user);
  }

  PubSub.subscribe('login.success', (msg, user) => {
    getCurrentUser();
  });

  useEffect(() => {
    getCurrentUser();
  }, []);

  function renderNavbar() {
    console.log(user);
    if (user && user.email) {

      if (user.role === 'admin') {
        return <NavbarAdmin />;
      } else {
        return <NavbarInternal />;
      }

    } else {
      return <Navbar />;
    }
  }

  return (
    <div style={{ overflowX: 'hidden', position: 'relative' }}>

      <BrowserRouter>
        {renderNavbar()}

        <BasicNotification />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="signin" element={<Signin />} />
          <Route path="user/profile" element={<UserProfile />} />
          <Route path="search" element={<Search />} />
          <Route path="admin/inbox/*" element={<Inbox />} />
          <Route path="admin/users" element={<UsersRegistered />} />
          <Route path="admin/users/new" element={<UserNew />} />
          <Route path="admin/users/detail/:id" element={<UserDetail />} />
          <Route path="admin/leads" element={<AdminLeads />} />
          <Route path="sequences" element={<Sequences />} />
          <Route path="admin/sequences" element={<AdminSequenceList />} />
          <Route path="sequences/:id" element={<SequenceDetail />} />
          <Route path="sequences/:id/contacts" element={<SequenceContacts />} />
          <Route path="sequences/:id/settings" element={<SequenceSettings />} />
          <Route path="sequences/:id/logs" element={<SequenceEmailsLogs />} />
          <Route path="sequences/:id/sequence-logs" element={<SequenceLogs />} />
          <Route path="sequences/:id/emails" element={<SequenceEmails />} />
          <Route path="sequences/:id/schedule" element={<SequenceSchedule />} />
          <Route path="auth/google/callback" element={<RedirectCode />} />
        </Routes>

      </BrowserRouter>


    </div>
  );
}

export default App;
