import { useEffect, useState } from "react";
import AuthService from "../Services/AuthService";
import UserService from "../Services/UserService";
import { Modal } from 'bootstrap';
import PubSub from 'pubsub-js';
import ModalService from "../Services/ModalService";
import SelectIndustries from "../Modals/SelectIndustries";



function Home() {

    const [show, setShow] = useState(false);
    const [selectedIndustries, setSelectedIndustries] = useState([]);
    const [user, setUser] = useState({});


    const showModal = async () => {
       ModalService.openSelectIndustriesModal();
    }


    async function getUserIndustries() {
        let userIndustries = await UserService.getUserIndustries();
        if (userIndustries.industries.length === 0) {
            showModal();
        } else {
            setSelectedIndustries(userIndustries.industries);
        }
    }

   
    const hideIndustriesModal = async () => {
        var myModal = Modal.getInstance(document.getElementById('select-industries-modal'));
        myModal.hide();
    }

   

    const getCurrentUser = async () => {
        let user = await AuthService.getCurrentUser();
        setUser(user);
    }

    useEffect(() => {
        getCurrentUser();
        getUserIndustries();
    }, []);


    

    console.log('selectedIndustries', selectedIndustries);

    return (
        <div>

            <div className="container">
                <div className="mt-5"></div>
                <h3 className="bold mb-4">Let's get started!</h3>

                <p>Hi {user.name}, welcome to Ulysses Leads!</p>

                <button className="btn btn-transparent" onClick={showModal}>Select industries</button>


            </div>


            <SelectIndustries />


        </div>
    )

}

export default Home;