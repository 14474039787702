import React from 'react';
import ReactDOM from 'react-dom/client';
import './globals'; // <-- first line
/**
 * STYLES
 */
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-tooltip/dist/react-tooltip.css'

import 'react-trumbowyg/dist/trumbowyg.min.css'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css';

import "jquery/dist/jquery.min.js";


import App from './App';

import '@popperjs/core/dist/umd/popper.min';
import 'jquery/dist/jquery.min';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
