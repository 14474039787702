import { useEffect, useState } from 'react';
import LeadsService from '../Services/LeadsService';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tooltip } from 'react-tooltip'
import SaveLeads from '../Modals/SaveLeads';
import { Modal } from 'bootstrap';
import { MODAL_SAVE_LEADS } from '../constants';
import UserService from '../Services/UserService';
import AuthService from '../Services/AuthService';
import PrebuiltTemplates from '../Modals/PrebuiltTemplates';
import ModalService from '../Services/ModalService';




function Search() {

    const [leads, setLeads] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [leadsSelected, setLeadsSelected] = useState([]);
    const [user, setUser] = useState({});


    useEffect(() => {
        getLeadsFromSearch();
        getCurrentUser();
    }, [page])

    const getCurrentUser = async () => {
        let user = await AuthService.getCurrentUser();
        setUser(user);
    }

    const getLeadsFromSearch = async () => {
        setIsLoading(true);
        let { data, total } = await LeadsService.getLeads(page);
        setLeads(data);
        setTotal(total);
        setIsLoading(false);
    }

    const unSelectAllLeads = async () => {
        setLeadsSelected([]);
    }


    const getAllLeadsIdsAvailable = async () => {

        let data = Array(5000).fill().map((_, idx) => ({ _id: `placeholder-${idx}` }));

        let leadsSelectedCopy = [...data];

        setLeadsSelected(leadsSelectedCopy);

    }

    const toggleLeadsCheckbox = () => {
        let leadsCopy = [...leads];
        let checked = leadsCopy[0].checked;
        leadsCopy.forEach(lead => lead.checked = !checked);
        setLeads(leadsCopy);

        let leadsSelectedCopy = [...leadsSelected];

        for (let i = 0; i < leadsCopy.length; i++) {
            let lead = leadsCopy[i];
            if (lead.checked) {
                // Check if lead is in leadsSelectedCopy
                let find = leadsSelectedCopy.find(l => l._id === lead._id);

                if (!find) {
                    leadsSelectedCopy.push(lead);
                }

            } else {

                leadsSelectedCopy = leadsSelectedCopy.filter(l => l._id !== lead._id);

            }
        }

        setLeadsSelected(leadsSelectedCopy);



    }

    const showSaveLeadsModal = () => {
        var myModal = Modal.getInstance(document.getElementById(MODAL_SAVE_LEADS));

        if (!myModal) {
            myModal = new Modal(document.getElementById(MODAL_SAVE_LEADS), {});
        }

        myModal.show();
    }

    const changePage = (page) => {
        if (page < 1) return;
        setPage(page);

    }

    function onAddClick() {
        ModalService.openSaveLeadsModal();
    }

    function onContinueClick() {
        ModalService.openPrebuiltTemplatesModal();
    }

    const renderPaginationSelect = () => {
        let options = [];
        for (let i = 0; i < total / 25; i++) {
            options.push(<option selected={page === i + 1}>{i + 1}</option>);
        }
        return options;
    }

    const renderSkeleton = () => {
        let templates = [];
        for (let i = 0; i < 25; i++) {
            let template = (
                <tr key={i}>
                    <td>
                        <div className="form-check">
                            <input className="form-check-input"
                            />
                            <label className="form-check-label ulysses-primary bold" htmlFor={'lead-select-' + i}>
                                <Skeleton width={150} />
                            </label>
                        </div>

                    </td>
                    <td> <Skeleton width={100} /></td>
                    <td> <Skeleton width={150} /></td>
                    <td> <Skeleton width={100} /></td>
                    <td> <Skeleton width={100} /></td>
                    <td> <Skeleton width={100} /></td>
                </tr>
            )
            templates.push(template);
        }
        return templates;
    }

    const onLeadSelect = (i) => {
        let leadsCopy = [...leads];
        leadsCopy[i].checked = !leadsCopy[i].checked;
        setLeads(leadsCopy);
        let lead = leadsCopy[i];
        let leadsSelectedCopy = [...leadsSelected];
        if (lead.checked) {

            let find = leadsSelectedCopy.find(l => l._id === lead._id);
            if (!find) {
                leadsSelectedCopy.push(lead);
            }
        } else {
            leadsSelectedCopy = leadsSelectedCopy.filter(l => l._id !== lead._id);
        }
        setLeadsSelected(leadsSelectedCopy);
    }

    const checkIfLeadIsSelected = (lead) => {
        let find = leadsSelected.find(l => l._id === lead._id);
        return find ? true : false;
    }

    const renderLeads = () => {
        return leads.map((lead, i) => (
            <tr key={i}>
                <td>
                    <div className="form-check">
                        <input className="form-check-input"
                            checked={checkIfLeadIsSelected(lead)}
                            onClick={() => onLeadSelect(i)}
                            type="checkbox" value="" id={'lead-select-' + i} />
                        <label className="form-check-label ulysses-primary bold" htmlFor={'lead-select-' + i}>
                            {lead.name}
                        </label>
                    </div>

                </td>
                <td>{lead.title}</td>
                <td>{lead.organization_name}</td>
                <td>{lead.industry}</td>
                <td>{lead.city}</td>
                <td>{lead.num_employees}</td>
            </tr>
        ));

    }



    return (
        <div className="ulysses-search-container">


            <div className="ulysses-search-content">

                <h5 className="my-3 ulysses-search-title">
                    Hi {user.name}, here are the leads we’ve selected for you based on the industries you chose.
                </h5>

                <div className="search-buttons-container">
                    <label className="form-check-label bold small me-3 mt-1" htmlFor={'lead-select'}>
                        {leadsSelected.length} Selected
                    </label>

                    <button
                        onClick={getAllLeadsIdsAvailable}
                        data-tooltip-id="search-tooltip"
                        data-tooltip-content="Select all the leads available (5000)"
                        className="btn btn-secondary btn-sm me-2">
                        <i className="far fa-check-square me-2"></i>
                        Select all
                    </button>

                    {/* 
 <button
                        onClick={toggleLeadsCheckbox}
                        className="btn btn-secondary btn-sm me-2">
                        <i className="far fa-check-square me-2"></i>
                        Select page
                    </button>
*/}


                    <button
                        onClick={unSelectAllLeads}
                        data-tooltip-id="search-tooltip"
                        data-tooltip-content="Unselect all the leads available"
                        className="btn btn-secondary btn-sm me-2">
                        <i className="far fa-check-square me-2"></i>
                        Clear all
                    </button>



                    <button
                        data-tooltip-id="search-tooltip"
                        data-tooltip-content="You need to click on the 'select all' option to continue!"
                        disabled={leadsSelected.length < 5000}
                        onClick={() => onContinueClick()}
                        className="btn btn-secondary btn-sm ">
                        <i className="fas fa-play me-2"></i>
                        Continue

                    </button>

                    {/* 
 <button
                        data-tooltip-id="search-tooltip"
                        data-tooltip-content="You need to select at least 500 leads!"
                        disabled={leadsSelected.length < 10}
                        onClick={() => onAddClick()}
                        className="btn btn-secondary btn-sm ms-2">
                        <i className="fas fa-plus me-2"></i>
                        Add
                    </button>
*/}

                </div>


                <div className="ulysses-search-table">
                    <table className="table table-bordered table-responsive small">
                        <thead>
                            <tr>
                                <th style={{ minWidth: 200 }}>Name</th>
                                <th>Title</th>
                                <th style={{ minWidth: 200 }}>Company</th>
                                <th style={{ minWidth: 200 }}>Industry</th>
                                <th>Location</th>
                                <th style={{ minWidth: 150 }}># Employees</th>
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ? renderSkeleton() : renderLeads()}
                        </tbody>
                    </table>
                </div>

                <div className="ulysses-search-pagination">
                    <div>{1 + ((page - 1) * 25)} - {25 + ((page - 1) * 25)} of {total} leads</div>
                    <button
                        onClick={() => changePage(page - 1)}
                        className="btn btn-secondary btn-sm ms-3">
                        <i className="fas fa-chevron-left"></i>
                    </button>
                    <select
                        value={page}
                        onChange={(e) => changePage(parseInt(e.target.value))}
                        className="btn btn-secondary">
                        {renderPaginationSelect()}
                    </select>
                    <button
                        onClick={() => changePage(page + 1)}
                        className="btn btn-secondary btn-sm">
                        <i className="fas fa-chevron-right"></i>
                    </button>

                </div>


            </div>
            <Tooltip id="search-tooltip" />
            <SaveLeads leadsSelected={leadsSelected} />

            <PrebuiltTemplates leadsSelected={leadsSelected} />
        </div>
    )
}

export default Search;