import { useEffect, useState } from "react"
import SequenceNav from "./SequenceNav"
import SequenceService from "../Services/SequenceService";
import { useParams } from "react-router";
import PubSub from 'pubsub-js';
import moment from 'moment';

function SequenceSettings() {

    const { id } = useParams();

    const [sequence, setSequence] = useState({
        name: '',
        delay_between_emails: 0,
        delay_between_emails_max: 0,
        max_emails_per_day: 10,
        mode: 'test',
        max_leads: 0,
        steps: []
    });

    useEffect(() => {
        getSequenceDetail();
    }, []);

    async function getSequenceDetail() {
        let sequence = await SequenceService.getSequenceDetail(id);
        setSequence(sequence);
    }

    async function updateSequence() {
        await SequenceService.updateSequence(sequence);
        PubSub.publish('show-basic-notification', {
            title: 'Sequence updated',
            message: 'Sequence has been updated.',
        });
    }

    async function resetStartDate() {
        if (window.confirm('Are you sure you want to reset the start date?')) {
            await SequenceService.resetSequenceStartDate(id);
            PubSub.publish('show-basic-notification', {
                title: 'Sequence start date reset',
                message: 'Sequence start date has been reset.',
            });
            getSequenceDetail();
        }
    }

    async function assignLeadsToSequence() {
        if(!window.confirm('Are you sure you want to assign leads to the sequence?')) return;
        await SequenceService.assignLeadsToSequence(id);
        PubSub.publish('show-basic-notification', {
            title: 'Leads assigned',
            message: 'Leads have been assigned to the sequence.',
        });
    }

    async function resetEmailSequenceForSequence() {
        if(!window.confirm('Are you sure you want to reset the emails processed?')) return;
        let {message} = await SequenceService.resetEmailSequenceForSequence(id);
        PubSub.publish('show-basic-notification', {
            title: 'Emails processed reset',
            message: message,
        });
    }

    return (
        <div>
            <SequenceNav />

            <div className="container">
                <div className="sequence-detail-container">


                    <div className="card">


                        <div className="card-body">
                            <h5>General settings</h5>
                            <br />
                            <div className="mb-3">
                                <label className="bold fs-12">Name</label>
                                <input type="text" className="form-control"
                                    onChange={(e) => setSequence({ ...sequence, name: e.target.value })}
                                    value={sequence.name} />
                            </div>

                            <div className="row mb-3">
                                <div className="col-6">
                                    <label className="bold fs-12">Mode</label>
                                    <select
                                        value={sequence.mode}
                                        onChange={(e) => setSequence({ ...sequence, mode: e.target.value })}
                                        className="form-control">
                                        <option value="test">Test</option>
                                        <option value="live">Live</option>
                                    </select>
                                </div>
                                <div className="col-3">
                                    <label className="bold fs-12">Start date</label>
                                    <input type="text"
                                        value={sequence.start_date ? moment(sequence.start_date).tz('America/Mexico_City').format('YYYY-MM-DD') : 'NA'}
                                        disabled
                                        className="form-control" />
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <div><label className="bold fs-12">Reset date</label></div>
                                        <button
                                            onClick={resetStartDate}
                                            disabled={!sequence.start_date}
                                            className="btn btn-danger btn-sm">
                                            Reset start date
                                        </button>
                                    </div>

                                </div>

                            </div>

                            <div className="row mb-3">
                                <div className="col-6">
                                    <label className="bold fs-12">Max Leads assigned</label>
                                    <input type="number" className="form-control"
                                        onChange={(e) => setSequence({ ...sequence, max_leads: e.target.value })}
                                        value={sequence.max_leads} />
                                </div>
                            </div>



                            <button onClick={updateSequence} className="btn btn-primary btn-sm">
                                Save changes
                            </button>

                        </div>
                    </div>

                    <div className="card mt-3">
                        <div className="card-body">
                            <h5>Additonal Settings</h5>
                            <br />


                            <div>
                                <button 
                                onClick={assignLeadsToSequence}
                                className="btn btn-primary btn-sm">
                                    Assign leads
                                </button>

                                <button 
                                onClick={resetEmailSequenceForSequence}
                                className="btn btn-primary btn-sm ms-2">
                                    Reset emails processed
                                </button>
                            </div>

                        </div>
                    </div>

                </div>



            </div>
        </div>
    )
}

export default SequenceSettings