import { NavLink, useLocation } from "react-router-dom";
import { useInboxCompose } from "./InboxComposeProvider"
import { useEffect, useState } from "react";
import InboxService from "../Services/InboxService";

function InboxSidebar() {

    const { openCompose } = useInboxCompose();
    const location = useLocation();

    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('');

    useEffect(() => {
        getAccountsWithInbox();
    }, [])

    useEffect(() => {
        getAccountFromQueryParams();
    }, [location.search]);

    function getAccountFromQueryParams() {
        let params = new URLSearchParams(location.search);
        let account = params.get('account');
        setSelectedAccount(account);
    }

    async function getAccountsWithInbox() {
        let accounts = await InboxService.getAccountsWithInbox();
        setAccounts(accounts);
    }

    function renderAccounts() {



        return accounts.map((account, index) => (
            <NavLink
                to={`/admin/inbox?account=${account}`}
                key={index}
                className={({ isActive }) => (isActive && selectedAccount === account) ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'}
                aria-current="true">
                <i className="fa-solid fa-inbox me-2"></i>
                {account}
            </NavLink>
        ));
    }

    return (
        <div className="inbox-sidebar">

            <button
                onClick={openCompose}
                className="inbox-compose-button my-2">
                <i class="fa-solid fa-pencil me-3"></i>
                Compose
            </button>

            <div class="list-group fs-13 mt-3">
                <NavLink
                    to={`/admin/inbox`}
                    className={({ isActive }) => (isActive && !selectedAccount) ? 'list-group-item list-group-item-action active' : 'list-group-item list-group-item-action'}
                    aria-current="true">
                    <i className="fa-solid fa-inbox me-2"></i>
                    Inbox
                </NavLink>
                {renderAccounts()}
            </div>


        </div>
    )

}

export default InboxSidebar