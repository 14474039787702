import { GET_LEADS, GET_LEADS_AVAILABLE_COUNT, GET_LEADS_IDS_AVAILABLE, GET_LEAD_LISTS_NAMES, SAVE_LEADS_INTO_LIST, UPLOAD_LEADS } from "../constants";
import Requests from "../requests";

const LeadsService = {
    getLeads(page = 1, limit = 25) {
        return Requests.getWithAuth(`${GET_LEADS}?page=${page}&limit=${limit}`);
    },
    getLeadListsNames() {
        return Requests.getWithAuth(GET_LEAD_LISTS_NAMES);
    },
    saveLeadsIntoList({leads_id, name}) {
        return Requests.postWithAuth(SAVE_LEADS_INTO_LIST, {leads_id, name});
    },
    getAllLeadsIdsAvailable() {
        return Requests.getWithAuth(GET_LEADS_IDS_AVAILABLE);
    },
    uploadLeadsUsingJSON(json) {
        return Requests.postWithAuth(UPLOAD_LEADS, json);
    },
    getLeadsAvailableCount() {
        return Requests.postWithAuth(GET_LEADS_AVAILABLE_COUNT);
    }
}

export default LeadsService;