import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UserService from "../Services/UserService";
import PubSub from "pubsub-js";

function AdminUserSettings() {

    const { id } = useParams();
    const [user, setUser] = useState({});

    useEffect(() => {
        getUserDetail();
    }, []);

    async function getUserDetail() {

        let user = await UserService.getUserById(id);
        setUser(user);

    }

    async function updateUser() {
        await UserService.updateUserById(id, user);

        PubSub.publish('show-basic-notification', {
            title: 'Success',
            message: `User settings has been updated`
        });
    }

    return (
        <div>
            <div className="card">
                <div className="card-header">
                    User settings
                </div>
                <div className="card-body">

                    <div className="form-group">
                        <label className="fs-13 bold">Name</label>
                        <input type="text"
                            value={user.name}
                            onChange={(e) => setUser({ ...user, name: e.target.value })}
                            className="form-control form-control-sm" />
                    </div>

                    <div className="form-group mt-2">
                        <div className="form-check">
                            <input className="form-check-input"
                                onChange={(e) => setUser({ ...user, debug: e.target.checked })}
                                checked={user.debug}
                                type="checkbox" value="" id={'user-debug'} />
                            <label className="fs-13" htmlFor="user-debug">Debug?</label>
                        </div>
                    </div>

                    <button 
                    onClick={updateUser}
                    className="btn btn-primary btn-sm mt-3">
                        Save settings
                    </button>


                </div>
            </div>
        </div>
    )

}

export default AdminUserSettings;