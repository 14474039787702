import { CREATE_USER_FROM_ADMIN, GET_USERS_REGISTERED, GET_USER_BY_ID, GET_USER_INDUSTRIES, GET_USER_PROFILE, GET_USER_PROFILE_BY_ID, PAUSE_USER, REMOVE_USER, SAVE_USER_INDUSTRIES, UPDATE_USER_BY_ID, UPDATE_USER_PROFILE, UPDATE_USER_PROFILE_BY_ID, UTILS_SEND_TEST_EMAIL } from "../constants";
import Requests from "../requests";

const UserService = {
    getUserIndustries() {
        return Requests.getWithAuth(GET_USER_INDUSTRIES);
    },
    saveUserIndustries(industries) {
        return Requests.postWithAuth(SAVE_USER_INDUSTRIES, { industries });
    },
    sendTestEmailToMe({ subject, body }) {
        return Requests.postWithAuth(UTILS_SEND_TEST_EMAIL, { subject, body });
    },
    getUserProfile() {
        return Requests.getWithAuth(GET_USER_PROFILE);
    },
    updateUserProfile(profile) {
        return Requests.postWithAuth(UPDATE_USER_PROFILE, profile);
    },
    getUserProfileById(id) {
        return Requests.getWithAuth(GET_USER_PROFILE_BY_ID.replace(':id', id));
    },
    updateUserProfileById(id, profile) {
        return Requests.postWithAuth(UPDATE_USER_PROFILE_BY_ID.replace(':id', id), profile);
    },
    getUsersRegistered() {
        return Requests.getWithAuth(GET_USERS_REGISTERED);
    },
    getUserById(id) {
        return Requests.getWithAuth(GET_USER_BY_ID.replace(':id', id));
    },
    updateUserById(id, user) {
        return Requests.postWithAuth(UPDATE_USER_BY_ID.replace(':id', id), user);
    },
    pauseUser(user_id, pause) {
        return Requests.postWithAuth(PAUSE_USER.replace(':id', user_id), { pause });
    },
    createUserFromAdmin(user) {
        return Requests.postWithAuth(CREATE_USER_FROM_ADMIN, user);
    },
    removeUser(user_id) {
        return Requests.postWithAuth(REMOVE_USER.replace(':id', user_id), {});
    }
}

export default UserService;