import { GET_CURRENT_USER, GET_REGISTER_URL, GET_USER_FROM_CODE, VALIDATE_IF_TOKEN_IS_VALID } from "../constants";
import Requests from "../requests";

const AuthService = {
    getRegisterUrl() {
        return Requests.get(GET_REGISTER_URL);
    },
    getUserFromCode(code) {
        return Requests.get(`${GET_USER_FROM_CODE}?code=${code}`);
    },
    saveAccessToken(token) {
        localStorage.setItem('access_token', token);
    },
    getAccessToken() {
        return localStorage.getItem('access_token');
    },
    validateIfTokenIsValid() {
        let token = localStorage.getItem('access_token');
        return Requests.get(`${VALIDATE_IF_TOKEN_IS_VALID}?access_token=${token}`);
    },
    getCurrentUser() {
        return Requests.getWithAuth(`${GET_CURRENT_USER}`);
    },
    logout() {
        localStorage.removeItem('access_token');
    }
}

export default AuthService;